import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Application/Application";
import { requestEmailCode } from "../Application/UseCases/Login/requestEmailCode";
import { requestPhoneCode } from "../Application/UseCases/Login/requestPhoneCode";
import { verifyEmailCode } from "../Application/UseCases/Login/verifyEmailCode";
import { verifyPhoneCode } from "../Application/UseCases/Login/verifyPhoneCode";
import Button from '../Components/Button';
import steamsLogo from '../assets/images/steam-logo-white.svg';
import packageJson from '../../package.json'; // Import package.json

// Styles
import styles from './Login.module.scss';

function stringStartsWith(str: string, search: string): boolean {
  str = str.toLowerCase();
  search = search.toLowerCase();

  return str.substring(0, search.length) === search;
}

function cleanLoginId(phone: string): string {
  const countryCode = '+1';

  if (stringStartsWith(phone, countryCode)) {
    // Remove country code
    phone = phone.substring(countryCode.length, phone.length);
  }

  // Numbers only
  phone = phone.replace(/\D/g, '');

  return countryCode + phone;
}

export function validateEmail(email: string): boolean {
  const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

  return regex.test(email);
}

export function validatePhone(phone: string): boolean {
  const len = phone.replace(/\D/g, '').length;
  return (len === 10);
}

function requestLoginCode(loginId) {
  if (validateEmail(loginId)) {
    requestEmailCode({ email: loginId });
  } else if (validatePhone(loginId)) {
    requestPhoneCode({ phone: loginId });
  } else {
    console.log('Invalid login format');
  }
}

function verifyLoginCode(loginId, loginCode) {
  if (validateEmail(loginId)) {
    verifyEmailCode({ email: loginId, code: loginCode });
  } else if (validatePhone(loginId)) {
    const number = cleanLoginId(loginId);
    verifyPhoneCode({ phone: number, code: loginCode });
  } else {
    console.log('Invalid login format');
  }
}

function Login() {
  const [loginId, setLoginId] = useState<string>("");
  const [loginCode, setLoginCode] = useState<string>("");
  const requestInProgress = useSelector((state: RootState) => state.Login.requestInProgress);
  const verifyInProgresss = useSelector((state: RootState) => state.Login.verifyInProgress);
  const requestSucceeded = useSelector((state: RootState) => state.Login.requestSucceeded);
  const verifySucceeded = useSelector((state: RootState) => state.Login.verifySucceeded);
  const disableRequestButton = loginId.length === 0 || requestInProgress || requestSucceeded;
  const disableVierfyButton = loginCode.length === 0 || verifyInProgresss || verifySucceeded;
  const errorMessage = useSelector((state: RootState) => state.Login.errorMessage);

  const handleSendLoginRequest = () => {
    requestLoginCode(loginId);
  };

  const handleValidateLoginCode = () => {
    verifyLoginCode(loginId, loginCode);
  };

  const handleKeyPressLoginRequest = (event) => {
    if (event.key === 'Enter') {
      handleSendLoginRequest();
    }
  };

  const handleKeyPressLoginCode = (event) => {
    if (event.key === 'Enter') {
      handleValidateLoginCode();
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <div className={styles.logo}>
        <img src={steamsLogo} width={200} alt="logo" />
        {/* Add version behind the logo */}
        <span className={styles.version}>v{packageJson.version}</span>
      </div>
      <div className={styles.loginContainer}>
        {(requestSucceeded || verifyInProgresss)
          ?
          <>
            <div className={styles.title}>Enter login code</div>
            <input
              type="text"
              value={loginCode}
              onChange={(e) => setLoginCode(e.target.value)}
              placeholder="Login Code"
              onKeyPress={handleKeyPressLoginCode}
            />
            <Button
              className={styles.loginBtn}
              label="VERIFY LOGIN CODE"
              theme="blue"
              disabled={disableVierfyButton}
              onClick={handleValidateLoginCode}
            />
          </>
          :
          <>
            <div className={styles.title}>Login with phone number or email</div>
            <input
              className={`${(errorMessage && loginId) && styles.invalid}`}
              type="text"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              placeholder="Phone Number or Email"
              onKeyPress={handleKeyPressLoginRequest}
            />
            <Button
              className={styles.loginBtn}
              label="REQUEST CODE"
              theme="blue"
              disabled={disableRequestButton}
              onClick={handleSendLoginRequest}
            />
          </>
        }
        <div className={styles.errorMsg}>{errorMessage}</div>
      </div>
    </div>
  );
}

export default Login;
