
import { closeModal } from '../Modal';
import Button from '../Button';

// Styles
import styles from './ConfirmationModal.module.scss';

interface Props {
  show?: string;
}

export default function ConfirmationModal(props: Props) {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mark}>
          <CheckMark />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <span>SUCCESS!</span>
          </div>
          <div className={styles.message}>
            <span>Your payment is approved and your order</span>
            <span>has been sent to manufacturing.</span>
            <span>You can review details for this order by clicking on the</span>
            <span>order number in the <b className={styles.strong}>sent to manufacturing</b> status.</span>
            <span>We'll notify you when your order has shipped</span>
          </div>
          <div className={styles.button}>
            <Button
              className={styles.buttonConfirm}
              label="AWESOME!"
              theme="blue"
              onClick={() => { closeModal() }}>
            </Button>
          </div>
        </div>
      </div >
    </>
  );
}

const CheckMark = () => {
  return (<>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      <circle className={[styles.path, styles.circle].join(' ')} fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
      <polyline className={[styles.path, styles.check].join(' ')} fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
    </svg>
  </>)
}

const CrossMark = () => {
  return (<>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
      <circle className={[styles.path, styles.circle].join(' ')} fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
      <line className={[styles.path, styles.line].join(' ')} fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
      <line className={[styles.path, styles.line].join(' ')} fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
    </svg>
  </>)
}

// How to use it
// renderInModal(
//   <ConfirmationModal />, "md"
// )
//
// "md" param is optional (set width to 500px)