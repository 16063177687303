import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Application/Application";
import { STeams } from "../../ExternalAPI/STeams";
import { createStripe } from "../../Utility/Session";

// Styles
import styles from './CheckoutForm.module.scss';

export default function CheckoutForm(props: any) {
  const isProductive: any = useSelector((state: RootState) => state.App.isProductive);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const redirectUrl = getRedirectUrl();

    STeams.createCheckoutSession(props.orderKey
      , props.partnersKey
      , props.customerKey
      , redirectUrl
      , props.serviceCode
      , props.live
      , props.liveRates)
      .then(checkoutSession => {
        createStripe(props.live)
          .then(stripe => {
            stripe.redirectToCheckout({
              sessionId: checkoutSession.id,
            }).then(result => {
              if (result.error) {
                setError(result.error.message);
              }
            })
          })
      }, error => {
        setError(error.response.data.error);
      })
  }, [props.orderKey]);

  const getRedirectUrl = () => {
    const isLocalhost = window.location.hostname === 'localhost';
    
    if (isLocalhost) {
      const port = window.location.port || (isLocalhost ? '3000' : '3010');
      const localhostURL = `${window.location.protocol}//${window.location.hostname}:${port}`;
      return localhostURL;
    } else {
      return `https://steamspartner.dd.poweredbycadworx.com${
        isProductive ? '/production/' : '/edge/'
      }`;
    }
  };

  return (
    <>
      {error ?
        <>
          <div className={styles.redirect}>
            <span className='icon fa-error'>
            </span> {error}
          </div>
        </>
        :
        <>
          <div className={styles.redirect}>
            <span className='icon fas fa-circle-notch fa-spin'>
            </span> Redirecting to payment interface...
          </div>
        </>
      }
    </>
  );
}
