import { STeams } from "../../../../ExternalAPI/STeams";

export async function getPartnerSessionData(sessionToken?: string, partnersKey?: string, superSessionToken?: string) {
  const partnerHasSessionResult = await STeams.partnerHasSession(sessionToken, partnersKey, superSessionToken);
  if(partnerHasSessionResult)
  {
    const hasSession = partnerHasSessionResult.hasSession;

    if (!hasSession){
      return Promise.reject('Partner does not have session');
    }

    const partnersKey = partnerHasSessionResult.partnersKey;

    return {
      partnersKey,
      hasSession,
      sessionToken: partnerHasSessionResult.sessionToken
    };
  }
  else{
    return Promise.reject('Undefined error when trying to access partner session data');
  }
}