import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { useSelector } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import { RootState } from "./Application/Application";
import { initPartnerData } from "./Application/UseCases/Init/initPartnerData";
import { setLiveCheckout } from './Application/UseCases/Init/setLiveCheckout';
import { setLiveRates } from './Application/UseCases/Init/setLiveRates';
import { getAllOrderStatuses } from './Application/UseCases/getAllOrderStatuses';
import { ordersCountByPartnersKey } from './Application/UseCases/ordersCountByPartnersKey';
import { setEnvironment } from './Application/UseCases/setEnvironmet';
import CustomMsgPopup from './Components/CustomMsgPopup/CustomMsgPopup';
import Login from "./Components/Login";
import MainNavigation from "./Components/MainNavigation";
import { renderInModal } from './Components/Modal';
import OrderDetails from './Components/Orders/OrderDetails';
import Orders from './Components/Orders/Orders';
import GrayGoods from "./Components/Pricing/GrayGoods";
import { parseBooleanFromUrlParam } from './Utility/Session';
import { checkForDuplicates } from './Utility/visitedOrdersByStatus';
import welcomeImage from './assets/images/welcome-image.svg';

// Styles
import styles from './App.module.scss';

function App() {
  const token = useSelector((state: RootState) => state.Login.sessionToken);
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);
  const params = parseBooleanFromUrlParam({ liveCheckout: 'cadx.liveCheckout', liveRates: 'cadx.liveRates' }, true);
  const { pathname } = window.location;

  const liveCheckout = params.liveCheckout;
  const liveRates = params.liveRates;
  setLiveCheckout({ liveCheckout });
  setLiveRates({ liveRates });

  if (token && partnersKey) {
    //initial count, then will set interval
    ordersCountByPartnersKey({ partnersKey: partnersKey })
    initPartnerData({ partnersKey: partnersKey });
    getAllOrderStatuses();

    const isProductive = pathname?.includes('/production/');
    setEnvironment({ isProductive });
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (partnersKey) {
        ordersCountByPartnersKey({ partnersKey: partnersKey });
        checkForDuplicates()
      }
      console.log('Function executed');
    }, 60000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(interval);
    };
  }, [partnersKey]);

  const WelcomeImage = () => {
    const url = 'https://steamspartner.dd.poweredbycadworx.com/production/';

    if (pathname?.includes('/edge/')) {
      renderInModal(
        <CustomMsgPopup
          title="You are trying to access a testing environment. If this is in error, please use this link instead: "
          url={url}
          showCancel={false}
          showConfirm={false}
        />, "lg", "");
    }

    return <div
      className={styles.imageContainer}>
      <img src={welcomeImage} alt="welcomeImage" />
    </div>;
  };

  const routes = [
    {
      path: ["/", "/edge/", "/production/", "/index.html/", "/edge/index.html", "/production/index.html"],
      component: WelcomeImage,
      exact: true
    },
    {
      path: "/orderDetails/:key",
      component: OrderDetails,
    },
    {
      path: "/orders",
      component: Orders,
      exact: true
    },
    {
      path: "/prices",
      component: GrayGoods,
      exact: true
    },
  ];

  return <Router>
    {token ? (
      <div className={styles.wrapper}>
        <div className={styles.overlay}></div>
        <div className={styles.App}>
          <MainNavigation />
          <div className={styles.MainContent}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  component={route.component}
                  exact={route.exact || false}
                />
              ))}
              <Redirect from="/index.html" to="/" />
              <Redirect from="/edge/index.html" to="/edge/" />
              <Redirect from="/production/index.html" to="/production/" />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    ) : (
      <Login />
    )}
  </Router>
}

export default App;