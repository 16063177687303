import { orderBy, set } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { RootState } from "../Application/Application";
import { OrderStatusData, OrderStatusId } from "../Application/Domain/Order";
import { signOut } from "../Application/UseCases/Login/signOut";
import { readOrdersByParams } from "../Application/UseCases/readOrdersByParams";
import { setActivePath } from "../Application/UseCases/setActivePath";
import { setActiveSportId } from "../Application/UseCases/setActiveSportId";
import { STeams } from "../ExternalAPI/STeams";
import { getStatusStorageKey } from "../Utility/Session";
import { isMobile } from "../Utility/isMobile";
import { useMedia } from "../Utility/matchMedia";
import useOutsideClick from "../Utility/useOutsideClick";
import Button from "./Button";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
import { renderInModal } from "./Modal";
import packageJson from '../../package.json'; // Import package.json

// Styles
import styled from "styled-components";
import styles from "./MainNavigation.module.scss";

const Bars = ({ open, setOpen }) => {
  return (
    <StyledNavIcons open={open} onClick={() => setOpen(!open)}>
      <div className={styles.navIcons}>
        <i className="fas fa-bars"></i>
        {open ? <i className="fas fa-times"></i> : null}
      </div>
    </StyledNavIcons>
  )
}

function MainNavigation() {
  const ordersCount: any = useSelector((state: RootState) => state.Order.ordersCount);
  const orderStatuses: any = useSelector((state: RootState) => state.App.allOrderStatuses);
  const currentOrderStatusId = useSelector((state: RootState) => state.Order.currentStatusId);
  const lastPageByStatus: any = useSelector((state: RootState) => state.Order.lastPageByStatus);
  const isProductive: any = useSelector((state: RootState) => state.App.isProductive);
  const currentSportId = useSelector((state: RootState) => state.Gui.activeSportId);
  const partner: any = useSelector((state: RootState) => state.App.partnerData);
  const sportId = partner.PartnerSport.find(g => g.sport.id == currentSportId)?.id;
  const partnerName = partner.Partner?.name;
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);
  let matchMedia = useMedia("(max-width: 850px)");
  const mobile = isMobile();
  const [searchText, setSearchText] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState((mobile || matchMedia) ? false : true);
  const navigationMenuRef = useRef();
  const searchInput = useRef(null);
  const history = useHistory();
  const activePath: any = useSelector((state: RootState) => state.Gui.activePath);
  const page: any = useSelector((state: RootState) => state.Order.pageActive)
  const pageSize: any = useSelector((state: RootState) => state.Order.pageSize)
  const [orderedStatuses, setOrderedStatuses] = useState<any[]>([]);
  const location = useLocation();
  const isOrdersPath = location.pathname.includes('/orders');
  const [lastOrdersCount, setLastOrdersCount] = useState<any[]>([]);

  useEffect(() => {
    if (ordersCount && orderStatuses) {
      // Temporary condition for StandBy
      const sentForManufacturingExists = ordersCount.some(obj => obj.orderStatusId === 'SentForManufacturing');

      const list = ordersCount?.reduce((matched: any[], ordersCountItem: any) => {
        const matchedObject = orderStatuses.find(obj => obj.orderStatusId === ordersCountItem.orderStatusId);
        if (matchedObject) {
          const combinedObject = { ...ordersCountItem, ...matchedObject };
          if (matchedObject.orderStatusId === 'StandBy') {
            combinedObject.showStatus = !sentForManufacturingExists;
            combinedObject.displayOrder = 3;
          }
          return [...matched, combinedObject];
        }

        return matched;
      }, []);

      const ordered = orderBy(list, ['displayOrder'], ['asc']);
      setOrderedStatuses([...ordered]);
    }
  }, [ordersCount, orderStatuses]);

  useEffect(() => {
    const shouldRefreshOrders = JSON.stringify(ordersCount) !== JSON.stringify(lastOrdersCount);
    if (shouldRefreshOrders) {
      readOrdersByParams({
        statusId: currentOrderStatusId,
        page: lastPageByStatus?.page || page,
        pageSize: pageSize,
        partnersKey: partnersKey
      })
      setLastOrdersCount(ordersCount);
    }
  }, [ordersCount]);

  useEffect(() => {
    if (window.location.href.indexOf("status=success") > -1) {
      renderInModal(
        <ConfirmationModal />, "md"
      );
      handleClick(OrderStatusId.PendingReview);
    }
  }, [partnersKey]);

  useEffect(() => {
    const overlay = document.getElementById('root').children[0].children[0];
    if ((open && matchMedia) || (open && mobile)) {
      // @ts-ignore
      overlay.style.display = 'block';
    } else {
      // @ts-ignore
      overlay.style.display = 'none';
    }
  }, [open]);

  useOutsideClick(navigationMenuRef, () => {
    if (document.activeElement !== searchInput.current) {
      setOpen(false);
    }
  });

  const initializeOrderStatus = (storageKey) => {
    const existingOrderStatus = localStorage.getItem(storageKey);
    if (!existingOrderStatus || !Array.isArray(JSON.parse(existingOrderStatus))) {
      localStorage.setItem(storageKey, JSON.stringify([]));
    }
  };

  const getBadgeNumber = (statusId, totalCount) => {
    const storageKey = getStatusStorageKey(statusId);
    initializeOrderStatus(storageKey);

    const existingOrderStatus = localStorage.getItem(storageKey);
    const visitedOrders = JSON.parse(existingOrderStatus);
    const visitedCount = Array.isArray(visitedOrders) ? visitedOrders.length : 0;
    const total = totalCount - visitedCount;

    return (total > 0) ? total : 0;
  };

  const showStatus = (statusId: string, numberOfOrders: number, showStatus: boolean): boolean => {
    // Temporary condition for StandBy
    switch (statusId) {
      case OrderStatusId.Cancelled:
        return false;
      case OrderStatusId.StandBy:
        if (showStatus)
          return true;
        else
          return false;
      case OrderStatusId.NeedsAttention:
      case OrderStatusId.PaymentFailed:
        return numberOfOrders !== 0;
      default:
        return true;
    }
  };

  function getDisplayName(id) {
    // Temporary condition for StandBy
    let sentForManufacturingName: string = "Sent For Manufacturing";
    const orderStatusDisplayName: OrderStatusData = orderStatuses?.find((os: any) => {
      return os.orderStatusId === id;
    });

    if (id === OrderStatusId.StandBy)
      return sentForManufacturingName;

    return orderStatusDisplayName.displayNames.partner;
  }

  function handleManagePricingClick(sportId) {
    setActivePath({ activePath: "/prices" });
    setActiveSportId({ activeSportId: sportId });
  }

  function handleClick(orderStatusId: string) {
    setActivePath({ activePath: "/orders" });
    const location = {
      pathname: '/orders'
    };
    history.push(location);
    readOrdersByParams({
      statusId: orderStatusId,
      page: page,
      pageSize: pageSize,
      partnersKey: partnersKey
    })
  }

  function handleSearchChange(newSearchText: string) {
    setSearchText(newSearchText);
    setDisabled(newSearchText.length > 0 ? false : true);
  }

  function logOut() {
    signOut();
    history.push(isProductive ? '../../production/' : '../../edge/');
  }

  async function searchOrder(searchText: string) {
    try {
      const ordersList = await STeams.getOrdersByPartner({
        partnersKey: partnersKey
      });

      const orderData = ordersList?.find((p: any) => p.order.ordersKey === searchText);

      const location = {
        pathname: `/orderDetails/${searchText}`,
        state: { orderData: orderData ? orderData : [] }
      };

      history.push(location);
    } catch (error) {
      console.error('Error searching for order:', error);
    }
  }

  return <>
    <div className={styles.MainNavUserWelcomeMobile}
      ref={navigationMenuRef}>
      <Bars
        open={!matchMedia || open}
        setOpen={setOpen} />
      Hello{partnerName && ", " + partnerName.trim()}!
    </div>
    <div className={styles.itemsMenu}>
      <StyledMainNavigation open={!matchMedia || open}>
        <div className={styles.MainNav}>
          <div className={styles.MainNavUserWelcome}>
            Hello{partnerName && ", " + partnerName.trim()}! (v{packageJson.version})
            <span onClick={() => logOut()}>Sign out</span>
          </div>
          <div className={styles.MainNavHeader}
            style={{ color: (activePath === '/prices') && '#FFFFFF' }}><i className="fas fa-star"></i>PRICING</div>
          {
            partner.PartnerSport.map((sport) => {
              const hasChildren = partner.PartnerGrayGood.filter(g => g.sport.id == sport.sport.id).length > 0;
              return hasChildren && <div key={sport.id}>
                <Link to={{
                  pathname: '/prices'
                }} className={styles.noneDecoration}>
                  <Button
                    className={`${sport.id === sportId ? `${styles.sideNavItemsActivePrices}` : `${styles.sideNavItemsPrices}`}`}
                    key={sport.id}
                    theme="noStyle"
                    label={sport.name}
                    active={sport.id === sportId && activePath === '/prices'}
                    onClick={() => handleManagePricingClick(sport.sport.id)}
                  />
                </Link>
              </div>
            })
          }
          <div className={styles.MainNavHeader}
            style={{ color: (activePath === '/orders') && '#FFFFFF' }}><i className="fas fa-square"></i>ORDERS</div>
          {
            orderedStatuses?.map(orderStatus => {
              return (showStatus(orderStatus.orderStatusId, orderStatus.orders_count, orderStatus.showStatus)) && <div key={orderStatus.orderStatusId}>
                {
                  <div className={styles.noneDecoration}>
                    <Button
                      badge={getBadgeNumber(orderStatus.orderStatusId, orderStatus.orders_count)}
                      className={`${orderStatus.orderStatusId === currentOrderStatusId
                        ? `${styles.sideNavItemsActive}`
                        : `${styles.sideNavItems}`}`}
                      key={orderStatus.orderStatusId}
                      theme="noStyle"
                      label={`${getDisplayName(orderStatus.orderStatusId)} (${orderStatus.orders_count})`}
                      active={orderStatus.orderStatusId === currentOrderStatusId && (activePath === '/orders' || isOrdersPath)}
                      onClick={() => handleClick(orderStatus.orderStatusId)}
                    ></Button>
                  </div>
                }
              </div>
            })
          }
          <div className={styles.searchContainer}>
            <div className={styles.MainNavHeader}>LOOKUP BY KEY</div>
            <input
              type="text"
              value={searchText}
              ref={searchInput}
              onChange={(e) => handleSearchChange(e.target.value)}
              placeholder="Search..."
            />
            <Button
              faIconCls="fa-search"
              label="LOOKUP ORDER"
              theme="blue"
              disabled={disabled}
              onClick={() => { searchOrder(searchText) }}>
            </Button>
          </div>
          <div className={styles.divider}></div>
          <Button
            faIconCls="fa-sign-out-alt"
            label="LOG-OUT"
            theme="gray"
            onClick={() => { logOut() }}>
          </Button>
        </div>
      </StyledMainNavigation>
    </div>
  </>
}

const StyledMainNavigation = styled.nav`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
`
const StyledNavIcons = styled.button`
  font-size: 1.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  color: #fff;

  &:focus {
    outline: none;
  }
`

export default withRouter(MainNavigation);
