export enum OrderStatusId {
  PendingReview = "PendingReview",
  PaymentFailed = "PaymentFailed",
  SentForManufacturing = "SentForManufacturing",
  NeedsAttention = "NeedsAttention",
  Cancelled = "Cancelled",
  ShippedToPartner = "ShippedToPartner",
  Complete = "Complete",
  StandBy = "StandBy"
}

export enum ManufacturingStatus {
  NotStarted = "NotStarted",   // StandBy
  InPrint = "InPrint",         // SentForManufacturing
  InSew = "InSew",             // SentForManufacturing
  Completed = "Completed"      // SentForManufacturing or Completed + Tracking = Shipped
}

export interface OrderStatusData {
  id?: string
  orderStatusId: OrderStatusId
  fixupsAllowed: boolean
  displayNames: {
    general: string
    coach: string
    partner: string
  }
  displayOrder: number
}
