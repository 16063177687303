import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import { RootState } from "../../Application/Application";
import { OrderStatusData, OrderStatusId } from "../../Application/Domain/Order";
import { setLastPageByStatus } from "../../Application/UseCases/setLastPage";
import { isoStringToPrettyDateAndTime } from "../../Utility/Date";
import Button from "../Button";
import Pagination from "../Pagination/Pagination";
import { onReadyClick, onResetStatus } from "./OrderActions";
import { showRedDot, updateOrderStatuses } from "../../Utility/visitedOrdersByStatus";

// Styles
import styles from "./Orders.module.scss";

interface OrdersProps {
  orders: Array<any>,
  currentOrderStatusId?: string,
  lastPageByStatus?: any,
  partnersKey?: string,
  customerKey?: string,
  orderStatuses: any
}

const OrdersTable = (props: OrdersProps) => {
  const partnersKey = props.partnersKey;
  const orders = props.orders?.sort((a, b) => {
    const dateA = new Date(a.order.createdOn).getTime();
    const dateB = new Date(b.order.createdOn).getTime();
    return dateB - dateA;
  });

  const lastPageByStatus = props.lastPageByStatus;
  const loading: any = useSelector((state: RootState) => state.Order.loading)
  const pageSize: any = useSelector((state: RootState) => state.Order.pageSize)
  const ordersCount: any = useSelector((state: RootState) => state.Order.ordersCount)
  const [page, setPage] = useState<number>(1);
  const ordersTotalCountByStatus = ordersCount && ordersCount?.find(o => o.orderStatusId == props.currentOrderStatusId)?.orders_count || 0;
  const totalPages = Math.ceil(ordersTotalCountByStatus / pageSize);
  const range = !isNaN(totalPages) ? Array.from(Array(totalPages + 1).keys()) : [];
  const liveCheckout = useSelector((state: any) => state.App.liveCheckout);
  const renderList = !loading && ordersTotalCountByStatus > 0;
  const showPaging = ordersTotalCountByStatus > pageSize && !loading;
  const history = useHistory();
  const [buttonStates, setButtonStates] = useState<any>({});

  useEffect(() => {
    if (props.currentOrderStatusId === lastPageByStatus?.statusId)
      setPage(lastPageByStatus?.page);
    else
      setPage(1);
  }, [props.currentOrderStatusId]);

  useEffect(() => {
    setLastPageByStatus({ statusId: props.currentOrderStatusId, page: page })
  }, [page]);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH' && location?.pathname === "/prices") {
        setLastPageByStatus({ statusId: props.currentOrderStatusId, page: 1 })
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const handleOrderStatusUpdate = (orderKey: string) => {
    updateOrderStatuses(orderKey, props.orderStatuses, props.currentOrderStatusId);
  };

  const shouldShowRedDot = (ordersKey: string) => {
    return showRedDot(ordersKey, props.currentOrderStatusId);
  };

  const updateButtonState = (orderKey, isLoading, notificationSent) => {
    setButtonStates(prev => ({
      ...prev,
      [orderKey]: { isLoading, notificationSent }
    }));
  };

  return <>
    <table className={styles.ordersTable}>
      <thead>
        <tr>
          <th>Order</th>
          <th>Created</th>
          <th>Products</th>
          <th>Organization</th>
          {
            (props.currentOrderStatusId === OrderStatusId.ShippedToPartner || props.currentOrderStatusId === OrderStatusId.Complete) &&
            <th></th>
          }
        </tr>
      </thead>
      <tbody>
        {
          renderList ? orders.map((orderData: any) => {
            const products = orderData.order.orderSnippetData.products;
            const shipToAddress: boolean
              = orderData.order.orderSnippetData?.deliveryMethod === "shipToAddress" && !!orderData.order.orderSnippetData?.shippingAddress;

            return <tr key={orderData.order.ordersKey}>
              <td data-label="Order">
                <Link
                  onClick={() => handleOrderStatusUpdate(orderData.order.ordersKey)}
                  to={{
                    pathname: `/orderDetails/${orderData.order.ordersKey}`
                    , state: {
                      orderData: orderData
                    }
                  }} >
                  {orderData.order.ordersKey} {shouldShowRedDot(orderData.order.ordersKey) ? <span className={styles.unopened}><i className="fas fa-circle"></i></span> : null}
                </Link>
              </td>
              <td data-label="Created">{isoStringToPrettyDateAndTime(orderData.order.createdOn)}</td>
              <td data-label="Products" className={`${products.length > 1 && styles.tdHeight}`}>

                <div className={`${products.length > 1 && styles.productsList}`}>
                  {
                    products?.map((productSnippetData: any) => {
                      return <React.Fragment key={productSnippetData.savedDocId}>
                        <div key={productSnippetData.savedDocId}>{`${productSnippetData.designStyleName} - ${productSnippetData.grayGoodName}`}</div>
                      </React.Fragment>
                    })
                  }
                </div>
              </td>
              <td data-label="Organization">{orderData.order.orderSnippetData?.coachContactInfo?.organizationName}</td>
              {props.currentOrderStatusId === OrderStatusId.ShippedToPartner &&
                <td className={styles.tdBtn}>
                  {(props.currentOrderStatusId === OrderStatusId.ShippedToPartner) &&
                    <div className={styles.shippedBtn}>
                      <Button
                        theme={buttonStates[orderData.order.ordersKey]?.notificationSent ? "green" : (shipToAddress ? "gray" : "blue")}
                        label={buttonStates[orderData.order.ordersKey]?.notificationSent ? "NOTIFICATION SENT!" : (shipToAddress ? "Click on order# for shipping details" : "Mark as ready for pickup")}
                        disabled={buttonStates[orderData.order.ordersKey]?.isLoading || shipToAddress}
                        handler={() => !buttonStates[orderData.order.ordersKey]?.isLoading && onReadyClick(orderData.order.ordersKey, props.currentOrderStatusId, partnersKey, false, updateButtonState)} />
                    </div>
                  }
                </td>
              }
              {props.currentOrderStatusId === OrderStatusId.Complete &&
                <td>
                  <Button theme="red" label="Reset" faIconCls="fa-a" handler={() => onResetStatus(orderData.order.ordersKey, props.currentOrderStatusId, partnersKey)} />
                </td>
              }
            </tr>
          })
            :
            [1, 2, 3, 4, 5].map((item: any, index: number) => (
              <tr key={index}>
                <td data-label="Order"><span className={styles.skeletonAnimation}></span></td>
                <td data-label="Created"><span className={styles.skeletonAnimation}></span></td>
                <td data-label="Products"><span className={styles.skeletonAnimation}></span></td>
                <td data-label="Organization"><span className={styles.skeletonAnimation}></span></td>
              </tr>
            ))}
      </tbody>
    </table>
    {(showPaging) ?
      <Pagination
        range={range}
        slice={10}
        setPage={setPage}
        page={page}
        currentStatusId={props.currentOrderStatusId}
        partnersKey={props.partnersKey} /> : null}</>
}

function Orders() {
  const orders = useSelector((state: RootState) => state.Order.orders);
  const orderStatuses = useSelector((state: RootState) => state.App.allOrderStatuses);
  const currentOrderStatusId = useSelector((state: RootState) => state.Order.currentStatusId);
  const orderStatusDisplayName: OrderStatusData = orderStatuses?.find((os: any) => {
    return os.orderStatusId === currentOrderStatusId;
  });
  const lastPageByStatus = useSelector((state: RootState) => state.Order.lastPageByStatus);
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);
  const customerKey = useSelector((state: RootState) => state.App.customerKey);

  return <React.Fragment>
    <div className={styles.header}>
      <div className={styles.headerText}>
        Order
        <i className="fas fa-chevron-right"></i>
        {/* Temporary StandBy condition ?? */}
        <span>{currentOrderStatusId === OrderStatusId.StandBy
          ? "Sent For Manufacturing"
          : orderStatusDisplayName?.displayNames.partner}
        </span>
      </div>
    </div>
    <OrdersTable orders={orders}
      currentOrderStatusId={currentOrderStatusId}
      lastPageByStatus={lastPageByStatus}
      partnersKey={partnersKey}
      customerKey={customerKey}
      orderStatuses={orderStatuses} />
  </React.Fragment>
}

export default withRouter(Orders);

