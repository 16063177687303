import JSZip from 'jszip';
import { saveAs } from "file-saver";

export function generateZipBlob(fileName, names, blobs) {
  let mainZip = new JSZip();
  fileName = fileName ? fileName + ".zip" : "download.zip";
  for (let i = 0; i < blobs.length; i++) {
    mainZip.file(names[i], blobs[i]);
  }

  mainZip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, fileName);
  });
}