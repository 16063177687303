function acronym(multipleWords: string) {
  return multipleWords.split(' ').map(function (item) { return item[0] }).join('');
}

export function prettyDateAndTime(date: Date) {
  if (!date)
    return 'Invalid date';
  const fullTime = date.toTimeString();
  const startIndexOfTimeZone = fullTime.indexOf('(');
  const endIndexOfTimeZone = fullTime.indexOf(')');
  const timeZone = fullTime.substring(startIndexOfTimeZone + 1, endIndexOfTimeZone);
  const timeZoneAcronym = acronym(timeZone);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} ${timeZoneAcronym}`;
}

export function isoStringToPrettyDateAndTime(dateString: string) {
  const d = new Date(dateString);
  return prettyDateAndTime(d);
}

export function addWorkDays(startDateValue, days) {
  const startDate = new Date(startDateValue.valueOf()); // Clone
  startDate.setDate(startDate.getDate() + days);

  return startDate;
}
