import {UseCase} from "../../TypesAndInterfaces/UseCase";
import {app} from "../../../index";

export type setLivCheckoutOptions = {
  liveCheckout: boolean;
}

export type setLiveCheckoutResult = {
  liveCheckout: boolean;
}

async function action(options: setLivCheckoutOptions):Promise<setLiveCheckoutResult> {

  return { liveCheckout: options.liveCheckout };
}

export const setLiveCheckoutUseCase:UseCase = {
  type: 'setLiveCheckout',
  run: action
};

export async function setLiveCheckout(options: setLivCheckoutOptions){
  await app.runUseCase(setLiveCheckoutUseCase, options);
}
