import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { requestPhoneCodeUseCase } from "../UseCases/Login/requestPhoneCode";
import { requestEmailCodeUseCase } from "../UseCases/Login/requestEmailCode";
import { verifyPhoneCodeUseCase } from "../UseCases/Login/verifyPhoneCode";
import { verifyEmailCodeUseCase } from "../UseCases/Login/verifyEmailCode";
import { partnerHasSessionUseCase } from "../UseCases/Login/partnerHasSession"
import { signOutUseCase } from "../UseCases/Login/signOut"

export interface LoginState {
  errorMessage: string,
  requestFailed: boolean,
  requestInProgress: boolean,
  partnersKey: string,
  sessionToken: string,
  phone: string,
  verifyInProgress: boolean,
  verifyFailed: boolean,
  verifySucceeded: boolean,
  requestSucceeded: boolean
}

const defaultLoginReducerData: LoginState = {
  errorMessage: undefined,
  sessionToken: undefined,
  requestFailed: false,
  verifyFailed: false,
  requestInProgress: false,
  partnersKey: undefined,
  phone: undefined,
  verifyInProgress: false,
  requestSucceeded: false,
  verifySucceeded: false
};


export const LoginReducer = function (state: LoginState = { ...defaultLoginReducerData }, useCaseResult: UseCaseResult) {
  switch (useCaseResult.type) {
    case requestEmailCodeUseCase.type:
    case requestPhoneCodeUseCase.type:
    {
      if (useCaseResult.start()) {
        return Object.assign({}, defaultLoginReducerData, {
          requestInProgress: true,
        });
      }

      else if (useCaseResult.failure()) {
        const errorMessage = useCaseResult.rejectionReason.errorMessage;

        return Object.assign({}, defaultLoginReducerData, {
          errorMessage,

          requestFailed: true,
          requestInProgress: false
        });
      }

      else if (useCaseResult.success()) {
        return Object.assign({}, defaultLoginReducerData, {
          phone: useCaseResult.data.phone,
          requestInProgress: false,
          requestSucceeded: true
        });
      }
      return state ;
    }

    case partnerHasSessionUseCase.type:
    {
      if (useCaseResult.start()) {
        return Object.assign({}, defaultLoginReducerData, {
          verifyInProgress: true,
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, defaultLoginReducerData, {
          partnersKey: useCaseResult.data.partnersKey,
          sessionToken: useCaseResult.data.sessionToken,
          verifySucceeded: true
        });
      }

      if (useCaseResult.failure()) {
        return Object.assign({}, defaultLoginReducerData, {
          verifyFailed: true 
        });
      }

      return state ;
    }

    case signOutUseCase.type:
    {
      if (useCaseResult.success()) {
        return Object.assign({}, state, defaultLoginReducerData);
      }
      return state ;
    }

    case verifyEmailCodeUseCase.type:
    case verifyPhoneCodeUseCase.type:
    {
      if (useCaseResult.start()) {
        return Object.assign({}, defaultLoginReducerData, {
          verifyInProgress: true,
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, defaultLoginReducerData, {
          partnersKey: useCaseResult.data.partnersKey,
          sessionToken: useCaseResult.data.sessionToken,
          verifySucceeded: true
        });
      }

      if (useCaseResult.failure()) {
        return Object.assign({}, defaultLoginReducerData, {
          errorMessage: useCaseResult.rejectionReason,
          verifyFailed: true 
        });
      }
      return state ;
    }

    default:
      return state;
  }
};


