import { app } from "../..";
import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";

export type ordersCountByPartnerResult = {
  ordersCountByPartner: {}
}

async function action(options: ordersCountByPartnersKeyUseCaseOptions): Promise<ordersCountByPartnerResult> {
    const allOrderStatuses = await STeams.ordersCountByPartner(options.partnersKey);

    return { ordersCountByPartner: allOrderStatuses };
}

export const ordersCountByPartnersKeyUseCase: UseCase = {
    type: 'ORDERS_COUNT_BT_PARTNER_ID',
    run: action
};

interface ordersCountByPartnersKeyUseCaseOptions {
    partnersKey: string
}

export async function ordersCountByPartnersKey(options: ordersCountByPartnersKeyUseCaseOptions) {
    await app.runUseCase(ordersCountByPartnersKeyUseCase, options);
}


