export function updatedBtn(currentElement) {
  const el = currentElement.children[0];
  el.style.display = "flex";
  el.style.backgroundColor = "#198754";
  el.children[1].style.display = "none";
  el.children[0].classList.add('fa', 'fa-check');
  el.children[0].style.transform = "scale(1.3)";

  const timer = setTimeout(() => {
    el.style.backgroundColor = "#1f76dc";
    el.children[0].classList.remove('fa', 'fa-check');
    el.children[0].style.transform = "scale(1)";
    el.children[1].style.display = "flex";
  }, 1000);
  return () => clearTimeout(timer);
}

export function updatedBtnFailed(currentElement) {
  const el = currentElement.children[0];
  el.style.display = "flex";
  el.style.backgroundColor = "#dc3545";
  el.children[1].style.display = "none";
  el.children[0].classList.add('fa', 'fa-times');
  el.children[0].style.transform = "scale(1.3)";

  const timer = setTimeout(() => {
    el.style.backgroundColor = "#1f76dc";
    el.children[0].classList.remove('fa', 'fa-times');
    el.children[0].style.transform = "scale(1)";
    el.children[1].style.display = "flex";
  }, 1000);
  return () => clearTimeout(timer);
}

// How to use it
//<div ref={elemRef}> -->  set Ref to btn container
//  <Button      
//     onClick={ } --> call update success or fail on promise 
//   />
//</div>