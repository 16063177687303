// Styles
import styles from './Button.module.scss';

const Button = (props: any) => {

  const handle = () => {
    if (props.handler)
      props.handler();

    // DropDownButton uses this
    if (props.onClick)
      props.onClick();
  };

  let badge = props.badge,
    disabled = props.disabled === true ? true : false,
    onClick = disabled ? null : handle,
    faIconCls = props.faIconCls || "",
    iconAlign = props.iconAlign,
    iconClsList = 'button-icon',
    badgeClass = "";

  if (faIconCls) {
    // Allow us to use far or fas icons
    if (faIconCls.indexOf('far') === -1 && faIconCls.indexOf('fas') === -1) {
      iconClsList += ' fa';
    }

    iconClsList += ' ' + faIconCls;
  }

  let classList = props.className || "";

  if (props.theme) {
    switch (props.theme) {
      case "white":
        classList = `${classList} ${styles.whiteTheme}`;
        break;
      case "green":
        classList = `${classList} ${styles.greenTheme}`;
        break;
      case "blue":
        classList = `${classList} ${styles.blueTheme}`;
        break;
      case "gray":
        classList = `${classList} ${styles.grayTheme}`;
        break;
      case "red":
        classList = `${classList} ${styles.redTheme}`;
        break;
      case "noStyle":
        classList = `${classList} ${styles.noStyle}`;
        break;
    }
  }

  let buttonIconCls = styles.buttonIcon;
  if (props.iconSize) {
    switch (props.iconSize) {
      case "large":
        buttonIconCls = styles.largeButtonIcon;
        break;
    }
  }

  if (disabled) {
    classList = `${classList} ${styles.disabled}`
  }

  if (iconAlign === 'top') {
    classList = `${classList} ${styles.flexColumn}`;
  }

  let title = props.title || '';

  if (props.theme && props.theme !== 'noStyle') {
    classList = `${styles.button} ${classList}`;
  }

  if (props.active) {
    classList = `${styles.active} ${classList}`;
  }

  return (
    <div title={title} className={`${classList}`} onClick={onClick}>
      <span className={`${buttonIconCls} ${iconClsList}`}></span>
      {Number.isInteger(badge) ?
        (badge !== 0 ? <span className={styles.badgeCls}><span>{badge}</span></span> : <span className={styles.emptyBadge}></span>) : null}
      <span className={styles.buttonLabel}>{props.label}</span>
    </div>
  );
};

export default Button;