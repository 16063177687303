import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";
import { onCancelClick } from "../../Components/Orders/OrderActions";

interface cancelOrderUseCaseOptions {
  orderKey: string,
  currentOrderStatusId: string,
  partnersKey: string
}

async function action(options: cancelOrderUseCaseOptions): Promise<cancelOrderUseCaseOptions> {
  await onCancelClick(options.orderKey, options.currentOrderStatusId, options.partnersKey);

  return options;
}

export const cancelOrderUseCase: UseCase = {
  type: 'CANCEL_ORDER',
  run: action
};

export async function cancelOrder(options: cancelOrderUseCaseOptions) {
  await app.runUseCase(cancelOrderUseCase, options);
}