import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";
import { OrderStatusId } from "../Domain/Order";

interface readOrdersByParamsUseCaseOptions {
  statusId: string,
  page: number;
  pageSize: number;
  partnersKey: string
}

export type readOrdersByParamsResult = {
  orders: [];
  page: number;
  pageSize: number;
  statusId: string;
}

async function action(options: readOrdersByParamsUseCaseOptions): Promise<readOrdersByParamsResult> {
    // Temporary condition for StandBy orders
  if (options.statusId === OrderStatusId.SentForManufacturing) {
    const [manufacturingOrders, standByOrders] = await Promise.all([
      STeams.getOrdersByPartner({
        partnersKey: options.partnersKey,
        page: 1,
        pageSize: 10000,
        statusId: OrderStatusId.SentForManufacturing
      }),
      STeams.getOrdersByPartner({
        partnersKey: options.partnersKey,
        page: 1,
        pageSize: 10000,
        statusId: OrderStatusId.StandBy
      })
    ]);

    const mergedOrders = [...manufacturingOrders, ...standByOrders].sort((a, b) => {
      const dateA = new Date(a.order.createdOn).getTime();
      const dateB = new Date(b.order.createdOn).getTime();
      return dateB - dateA;
    });

    const startIdx = (options.page - 1) * options.pageSize;
    const endIdx = startIdx + options.pageSize;

    const paginatedOrders: any = mergedOrders.slice(startIdx, endIdx);

    return {
      orders: paginatedOrders,
      page: options.page,
      pageSize: options.pageSize,
      statusId: OrderStatusId.SentForManufacturing
    };
  }

  const orders = await STeams.getOrdersByPartner({
    partnersKey: options.partnersKey,
    page: options.page,
    pageSize: options.pageSize,
    statusId: options.statusId
  });

  return { orders, page: options.page, pageSize: options.pageSize, statusId: options.statusId };
}

export const readOrdersByParamsUseCase: UseCase = {
  type: 'readOrdersByParams',
  run: action
};

export async function readOrdersByParams(options: readOrdersByParamsUseCaseOptions) {
  await app.runUseCase(readOrdersByParamsUseCase, options);
}
