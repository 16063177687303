import { useState, useEffect, useCallback } from 'react';
import { STeams } from '../ExternalAPI/STeams';
import { saveAs } from 'file-saver';

// Styles
import styles from './Receipt.module.scss';

interface ReceiptProps {
  orderData: any;
  partnerData: any;
  coachData: any;
  isInvoice?: boolean;
}

function toArrayBuffer(buf: Buffer): ArrayBuffer {
  const ab = new ArrayBuffer(buf.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
}

export function Receipt({ orderData, partnerData, coachData, isInvoice }: ReceiptProps) {
  const [loading, setLoading] = useState(false);

  const formatDate = useCallback((createdOn: string) => {
    const date = new Date(createdOn);
    const dateYMD = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    return dateYMD;
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const response = await STeams.generateReceipt(orderData, partnerData?.Partner, isInvoice);
      const ret = await response;
      const blobs: Blob[] = [];
      const createdOn = formatDate(orderData.createdOn);
      // ORDERKEY_COACHNAME_ORDERDATE_RECEIPT
      const name = isInvoice ? 'INVOICE' : 'RECEIPT';
      const nameData = [orderData.ordersKey, '_', coachData?.name, '_', createdOn, '_', name];
      const fileName = nameData.join('');

      ret.forEach((xlsData: any) => {
        const buffer = toArrayBuffer(xlsData.data);
        const xlsBlob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        blobs.push(xlsBlob);
      });

      saveAs(blobs[0], (fileName ? fileName : (isInvoice ? "Invoice.xlsx" : "Receipt.xlsx")));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [formatDate, orderData, partnerData, coachData]);

  useEffect(() => {
    if (loading) {
      fetchData();
    }
  }, [loading, fetchData]);

  const handleClick = useCallback(() => {
    setLoading(true);
  }, []);

  return (
    <div className={styles.linksWrapper} style={{ pointerEvents: loading ? 'none' : 'auto', opacity: loading ? '0.7' : '1' }}>
      <div className={styles.link} onClick={handleClick}>
        <i className={loading ? 'icon fas fa-circle-notch fa-spin' : (isInvoice ? 'icon fas fa-light fa-file-invoice': 'icon fas fa-light fa-receipt')}></i>
        <a>{isInvoice ? "INVOICE" : "RECEIPT"}</a>
      </div>
    </div>
  );
}
