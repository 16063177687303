import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams, withRouter } from "react-router-dom";
import { RootState } from "../../Application/Application";
import { ManufacturingStatus, OrderStatusId } from "../../Application/Domain/Order";
import { cancelOrder } from "../../Application/UseCases/cancelOrder";
import { getCustomerInfoReadByKey } from "../../Application/UseCases/getCustomerInfoReadByKey";
import { STeams } from "../../ExternalAPI/STeams";
import { addWorkDays, prettyDateAndTime } from '../../Utility/Date';
import { getOrdersStorageKey } from "../../Utility/Session";
import { isMobile } from "../../Utility/isMobile";
import { useMedia } from "../../Utility/matchMedia";
import Button from "../Button";
import CustomMsgPopup from "../CustomMsgPopup/CustomMsgPopup";
import { renderInModal } from "../Modal";
import { PackingSlip } from "../PackingSlip";
import { Receipt } from "../Receipt";
import ReviewItem from "../ReviewItem";
import ShipToModal from "../ShipToModal/ShipToModal";
import TrackingNumber from "../TrackingNumberModal/TrackingNumberModal";
import { onApproveAndPayClick, onPayClick, onReadyClick } from "./OrderActions";

// Styles
import styles from './OrderDetails.module.scss';

export interface Address {
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  postalCode?: string;
  salesTaxType?: string;
  state?: string;
  frontEndVisible?: boolean;
};

function OrderDetails() {
  const [updateOrderDetails, setUpdateOrderDetails] = useState<boolean>(false);
  let { key } = useParams();
  const history = useHistory();
  const [orderData, setOrderData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [noOrderFound, setNoOrderFound] = useState<boolean>(false);
  const [updatingProductsLoading, setUpdatingProductsLoading] = useState(false);
  const [updatingValues, setUpdatingValues] = useState<boolean>(false);
  const [orderStatusDisplayName, setOrderStatusDisplayName] = useState<any | null>(null);
  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [orderId, setOrderId] = useState<string | null>(null);
  const [estimatedShipping, setEstimatedShipping] = useState<any>(null);
  const [estimatedDelivery, setEstimatedDelivery] = useState<any>(null);
  const [estimatedShippingMessage, setEstimatedShippingMessage] = useState<string>('Estimated Ship Date');
  const [estimatedShippingMessagePlacingOrder, setEstimatedShippingMessagePlacingOrder] = useState<string>('* if order is placed today');
  const [estimatedDeliveryMessage, setDeliveryShippingMessage] = useState<string>(null);
  const partnersKey: any = useSelector((state: RootState) => state.Login.partnersKey);
  const partner: any = useSelector((state: RootState) => state.App.partnerData);
  const isProductive: any = useSelector((state: RootState) => state.App.isProductive);
  const [retailProductPrice, setRetailProductPrice] = useState<any>(null);
  const [minOrderFeePrice, setMinOrderFeePrice] = useState<any>(null);
  const [minOrderFeePriceAtPayment, setMinOrderFeePriceAtPayment] = useState<any>(0);
  const [partnerProductCost, setPartnerProductCost] = useState<any>(null);
  const [retailShippingPrice, setRetailShippingPrice] = useState<any>(null);
  const [partnerShippingCost, setRetailPartnerShippingCost] = useState<any>(null);
  const [totalRetailPrice, setTotalRetailPrice] = useState<any>(null);
  const [totalPartnerCost, setTotalPartnerCost] = useState<any>(null);
  const [totalProductPriceMinusShippingCost, setProductPriceMinusShippingCost] = useState<any>(null);
  const [totalProductCostMinusShippingCost, setProductCostMinusShippingCost] = useState<any>(null);
  const [taxes, setTaxes] = useState<any>(null);
  const [oversizePrice, setOversizePrice] = useState<any>(null);
  const [oversizePriceAtPayment, setOversizePriceAtPayment] = useState<any>(0);
  const [orderStatusId, setOrderStatusId] = useState<string>("");
  const [changingOrderStatus, setChangingOrderStatus] = useState<boolean>(false);
  const [taxHeight, setTaxHeight] = useState<any>(null);
  const taxElm = useRef(null);
  const [defaultAddress, setDefautlAddress] = useState<Address>({});
  const [selectedStore, setSelectedStore] = useState<Address>({});
  const setSelectedStoreSuccess: boolean = useSelector((state: RootState) => state.App.setSelectedStoreSuccess);
  const setNewAddressOptionSuccess: boolean = useSelector((state: RootState) => state.App.setNewAddressOptionSuccess);
  const [showExclamationIcon, setShowExclamationIcon] = useState<boolean>(false);
  const [isStoreAvailable, setIsStoreAvailable] = useState<boolean>(true);
  const [orderStore, setOrderStore] = useState<any>({});
  const [shipperRates, setShipperRates] = useState<any>(undefined);
  const [selectedShipperRateCode, setSelectedShipperRateCode] = useState<any>(undefined);
  const triggerEvent: any = isMobile() ? "click" : "hover";
  let matchMedia = useMedia("(max-width: 1500px)");
  const [unavailableAddressMsg, setUnavailableAddressMsg] = useState<string>("");
  const [showShippingAddress, setShowShippingAddress] = useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<any>(undefined);
  const [openContactAddress, setOpenContactAddress] = useState<boolean>(false);
  const [trackingNumber, setTrackingNumber] = useState<string>("");
  const [lowDpiLogos, setLowDpiLogos] = useState<any>([]);
  const [isFreeShipping, setIsFreeShipping] = useState<boolean>(false);
  const [costTaxDescription, setCostTaxDescription] = useState<string>("TBD");
  const [productsData, setProductsData] = useState<any>([]);
  const [checkedRate, setCheckedRate] = useState<boolean>(false);
  const [buttonStates, setButtonStates] = useState<any>({});
  const [trackingId, setTrackingId] = useState<string>("");
  const [manufacturingStatus, setManufacturingStatus] = useState<string>("");

  function setSelectedShipperRate(orderKey, orderId, rates, rateCode) {
    const r = rates.rates.find(r => r.code == rateCode)
    const price = r && r.amount.amount;

    if (price)
      setRetailPartnerShippingCost(parseFloat(price));

    setSelectedShipperRateCode(rateCode);
    setCheckedRate(true);

    STeams.setShippingRateCode(orderKey, orderId, rateCode, price)
      .then((r) => {
        //setSelectedShipperRateCode(rateCode)
      });
  }

  // TODO: Make base configurable
  const token = localStorage.getItem('steamspartner.apiToken') ?? '';
  const openOrderUrl = `https://${isProductive ? 'www' : 'edge'}.uniformcreator.com/?pk=${partnersKey}&app.orderId=${orderKey}&app.openAsCopy=true&apiToken=${token}`;
  const customerInfo: any = useSelector((state: RootState) => state.Order.coachContactInfo);
  const live = useSelector((state: any) => state.App.liveRates);
  const validStatuses: OrderStatusId[] = [OrderStatusId.PaymentFailed, OrderStatusId.PendingReview]; // it's being used to hide the EDIT button as well

  useEffect(() => {
    if (Object.entries(partner.Partner).length > 0 && Object.entries(selectedStore).length > 0) {
      const addressOptions = partner?.Partner.addressOptions;
      const defaultPartnerAddress: Address = getAddress(defaultAddress) || {};
      const addresses = addressOptions.concat(defaultPartnerAddress);

      const isAddressAvailable = addresses.find((a: any) => {
        return a.name === selectedStore.name;
      });

      if (isAddressAvailable && Object.entries(isAddressAvailable).length > 0) {
        setIsStoreAvailable(true);
      } else {
        setIsStoreAvailable(false);
      }
    }
  }, [partner, selectedStore]);

  useEffect(() => {
    if (orderData && partner && orderStatusId) {
      const paymentStatuses: OrderStatusId[] = [OrderStatusId.PaymentFailed, OrderStatusId.PendingReview];

      // If order is PendingReview or PaymentFailed check the condition at Partner level
      if (paymentStatuses.includes(orderStatusId as OrderStatusId)) {
        setCostTaxDescription(partner?.Partner.isTaxExempt ? "Tax Exempt" : "TBD");
      } else {
        // Check condition at order level in order to display 
        // the accurate information about the moment when the payment was made  
        setCostTaxDescription(orderData.orderSnippetData?.exemptFromPartnerCostTax ? "Tax Exempt" : "TBD");
      }
    }
  }, [orderData, partner, orderStatusId])

  useEffect(() => {
    // Validate order prices on load time
    if (productsData.length > 0 && !updatingProductsLoading && partnersKey) {
      if (validStatuses.includes(orderStatusId as OrderStatusId)) {
        setUpdatingValues(true);

        // Update order
        STeams.updateOrder(orderKey, orderId, productsData, partnersKey)
          .then((res) => {
            setUpdateOrderDetails(true);
            setUpdatingValues(false);

            // Check shipping rates
            STeams.getShipperRates(orderData, live)
              .then((r) => {
                if (r.rates && r.rates.length > 0) {
                  r.rates = r.rates.sort((a, b) => (parseFloat(a.amount.amount) > parseFloat(b.amount.amount) ? 1 : -1));
                  setShipperRates(r);

                  const rates = selectedShipperRateCode && r.rates.find(r => r.code == selectedShipperRateCode)
                  const price = rates && rates.amount.amount;

                  if (rates)
                    setCheckedRate(true)

                  // Check if shipping price is correct
                  const updateShipping = orderData.orderSnippetData?.shipRateCode === rates?.code && parseFloat(price) !== partnerShippingCost;

                  if (!selectedShipperRateCode || updateShipping)
                    setSelectedShipperRate(orderKey, orderId, r, r.rates[0].code);
                  else {
                    setRetailPartnerShippingCost(parseFloat(price));
                  }
                }
              })
          })
          .catch((error: any) => {
            setUpdateOrderDetails(false);
            setUpdatingValues(false);
            console.log(error);
          });

        setUpdatingProductsLoading(true)
      }
    }
  }, [partner, productsData])

  useEffect(() => {
    STeams.getOrdersReadByKeyUrl(key)
      .then(
        (result) => {
          if (result.order && result.orderStatus) {
            const resultOrderData = result.order[0];
            setOrderData(resultOrderData);
            const orderStatus = result.orderStatus.slice(-1)[0];
            // Temporary condition for StandBy status
            const orderStatusDisplayName = (orderStatus.orderStatusId === OrderStatusId.StandBy)
              ? "Sent For Manufacturing" : orderStatus?.displayNames.general;
            const shipCode = resultOrderData.orderSnippetData?.shipRateCode;
            const defaultAddress: Address = getAddress(resultOrderData.orderSnippetData.partnerInfo) || {};
            const storeLocation: Address = getAddress(resultOrderData.orderSnippetData.storeLocation) || defaultAddress;

            // Check if the Shipping Address should be shown as "SHIP TO" and replace the coach address 
            const shipToAddress = (resultOrderData.orderSnippetData?.deliveryMethod === "shipToAddress" && resultOrderData.orderSnippetData?.shippingAddress) ? true : false;
            setShowShippingAddress(shipToAddress);
            setShippingAddress(resultOrderData.orderSnippetData?.shippingAddress);
            setTrackingNumber(shipToAddress ? resultOrderData.orderSnippetData?.trackingNumber : "N/A");
            setOrderStatusId(orderStatus?.orderStatusId)
            setOrderStatusDisplayName(orderStatusDisplayName);
            setOrderKey(resultOrderData.ordersKey);
            setOrderId(resultOrderData.id);
            getCustomerInfoReadByKey({
              coachesKey: resultOrderData.coach.coachesKey,
              customerInfoKey: resultOrderData.customerInfoKey
            });

            // Get manufacturing status
            const restoConfig = resultOrderData.orderSnippetData?.restoConfig;
            const mStatus = restoConfig?.manufacturingStatus;

            if ((orderStatus?.orderStatusId === OrderStatusId.StandBy || orderStatus?.orderStatusId === OrderStatusId.SentForManufacturing)
              && mStatus !== ManufacturingStatus.NotStarted && mStatus !== "Not Started")
              setManufacturingStatus(mStatus);

            let orderDays = 0;

            if (shipCode)
              setSelectedShipperRateCode(shipCode);

            const selectedShippingDays = parseInt(resultOrderData.orderSnippetData.shippingMethod?.days);
            getEstimatedDatesMessage(orderStatus?.orderStatusId, orderStatus?.date, orderDays, selectedShippingDays, result.orderStatus, resultOrderData.orderSnippetData);
            setDefautlAddress({ ...defaultAddress });
            setSelectedStore({ ...storeLocation });
            setOrderStore({ ordersKey: resultOrderData.ordersKey, storeName: storeLocation.name });

            const isObjectEmptyOrNull = obj => obj === null || obj === undefined || (typeof obj === 'object' && Object.keys(obj).length === 0);
            const isFreeShipping = !isObjectEmptyOrNull(resultOrderData.orderSnippetData.freeShipping) ? (resultOrderData.orderSnippetData.shippingMethod.code === resultOrderData.orderSnippetData.freeShipping.code) : false;
            let shippingPrice = fixPrice(resultOrderData.orderSnippetData.shippingMethod?.Cost || resultOrderData.orderSnippetData.shippingMethod?.cost);
            const minOrderFee = resultOrderData.orderSnippetData.minOrderFee;
            const minOrderFeePriceAtPayment = resultOrderData.orderSnippetData.minFeeAtPayment || minOrderFee;

            const shippingCost = resultOrderData.orderSnippetData.shipRatePrice
              ? fixPrice(resultOrderData.orderSnippetData.shipRatePrice)
              : fixPrice(resultOrderData.orderSnippetData.shippingMethod?.cost || resultOrderData.orderSnippetData.shippingMethod?.Cost);

            let priceOffset = 0;
            result.order.forEach((order) => { order.orderState.forEach((state) => { priceOffset += state.TotalSizePriceOffset }) });
            const offsetPriceAtPayment = resultOrderData.orderSnippetData.totalPriceOffsetAtPayment || priceOffset;

            const totalProductPrice = fixPrice(resultOrderData.orderSnippetData.retailTotal);

            // Check if fee values need to be added to total cost
            const isStatusToUpdateCost = !validStatuses.includes(orderStatusId as OrderStatusId);
            const hasNoMinFeeAtPayment = !resultOrderData.orderSnippetData.minFeeAtPayment;
            const hasNoTotalPriceOffsetAtPayment = !resultOrderData.orderSnippetData.totalPriceOffsetAtPayment;

            const feeOnTotalCost = isStatusToUpdateCost && hasNoMinFeeAtPayment && hasNoTotalPriceOffsetAtPayment ? (minOrderFee + priceOffset) : 0;
            const totalProductCost = fixPrice(resultOrderData.orderSnippetData.costTotal) - feeOnTotalCost;

            if (!shippingPrice)
              shippingPrice = shippingCost;

            const totalProductPriceMinusShippingCost = totalProductPrice; //- shippingPrice;
            const totalProductCostMinusShippingCost = totalProductCost; //- shippingCost;
            const taxes = fixPrice(resultOrderData.orderSnippetData.salesTax);

            setIsFreeShipping(isFreeShipping);
            setOversizePrice(priceOffset);
            setOversizePriceAtPayment(offsetPriceAtPayment);
            setTaxes(taxes);
            setRetailProductPrice(totalProductPrice);
            setPartnerProductCost(totalProductCost);
            setRetailShippingPrice(shippingPrice);
            setMinOrderFeePrice(minOrderFee);
            setMinOrderFeePriceAtPayment(minOrderFeePriceAtPayment);
            setRetailPartnerShippingCost(shippingCost);
            setProductPriceMinusShippingCost(totalProductPriceMinusShippingCost);
            setProductCostMinusShippingCost(totalProductCostMinusShippingCost);
            if (shipToAddress && orderStatus?.orderStatusId === OrderStatusId.Complete)
              setOrderStatusDisplayName("COMPLETE")

            // Check if order contains products with low DPI logos
            if (resultOrderData.orderState?.length > 0) {
              const products = resultOrderData.orderState;
              setProductsData(products);
              products.forEach((product) => {
                let logoArray = [];
                product?.UploadedLogos && product.UploadedLogos.find((logo) => {
                  const dpiNumber = Math.ceil(logo[product.ItemNumber]?.dpi) || null;
                  // 300 DPI === 118 PPI. Some pics hasn't set the 300DPI values on their properties and they are taken as 118 PPI
                  // set 250 as new minimum value
                  if (dpiNumber && (dpiNumber < 119 || (dpiNumber > 119 && dpiNumber < 250))) {
                    logoArray.push(logo);
                    setLowDpiLogos([...logoArray]);
                  }
                })
              })
            }
          } else if (!result.order && !result.orderStatus) {
            setNoOrderFound(true);
            setOrderData(null);
          } else {
            return;
          }

          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [key, updateOrderDetails]);

  function fixPrice(price) {
    return parseFloat(parseFloat(price).toFixed(2))
  }

  function getAddress(data: any) {
    if (data?.defaultAddressName || data?.name) {
      return {
        name: data.defaultAddressName || data.name,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        postalCode: data.postalCode,
        salesTaxType: data.salesTaxType
      }
    } else {
      return "";
    }
  }

  const getEstimatedDatesMessage = (orderStatusId: string, orderStatusDate: string, rushTypeDays: number, selectedShippingDays: number, orderStatuses: any[], orderSnippetData?: any) => {
    const baseDate = (orderStatusId === OrderStatusId.PendingReview || orderStatusId === OrderStatusId.PaymentFailed) ? new Date() : new Date(orderStatusDate);
    const shipDate = addWorkDays(baseDate, rushTypeDays > 0 ? rushTypeDays : 14);
    const deliveryDate = addWorkDays(shipDate, selectedShippingDays);

    const shipDateRedeable = prettyDateAndTime(shipDate);
    const deliveryDateRedeable = prettyDateAndTime(deliveryDate);
    const restoConfig = orderSnippetData?.restoConfig;
    setTrackingId(restoConfig?.trackingId);

    setEstimatedShipping(shipDateRedeable);
    setEstimatedDelivery(deliveryDateRedeable);

    switch (orderStatusId) {
      case OrderStatusId.PendingReview:
        break;
      case OrderStatusId.SentForManufacturing:
      case OrderStatusId.StandBy:
        setEstimatedShippingMessagePlacingOrder("");
        break;
      case OrderStatusId.ShippedToPartner:
        orderStatuses.forEach((status: any) => {
          if (status.orderStatusId === OrderStatusId.ShippedToPartner) {
            const shipped = prettyDateAndTime(new Date(status.date));
            setEstimatedShipping(shipped);
          }
        });
        setEstimatedShippingMessage("Shipped on");
        setEstimatedShippingMessagePlacingOrder("");
        setDeliveryShippingMessage("TRACKING");
        setEstimatedDelivery(restoConfig?.trackingId || "N/A");
        break;
      case OrderStatusId.PaymentFailed:
        break;
      case OrderStatusId.NeedsAttention:
        setEstimatedShippingMessage("Estimated Ship Date");
        setEstimatedShippingMessagePlacingOrder("");
        setEstimatedShipping("TBD");
        setEstimatedDelivery("TBD");
        break;
      case OrderStatusId.Complete:
        if (orderStatuses.length > 0) {
          orderStatuses.forEach((status: any) => {
            if (status.orderStatusId === OrderStatusId.Complete) {
              const complete = prettyDateAndTime(new Date(status.date));
              if (orderSnippetData?.deliveryMethod === "shipToAddress" && orderSnippetData?.shippingAddress) {
                setEstimatedDelivery(restoConfig?.trackingId || "N/A");
                setEstimatedShipping(complete);
                setEstimatedShippingMessagePlacingOrder("");
                setEstimatedShippingMessage("Shipped on");
                setDeliveryShippingMessage("TRACKING");
              } else {
                setEstimatedDelivery(complete);
                setEstimatedShippingMessagePlacingOrder("");
                setDeliveryShippingMessage("Delivery Date");
              }
            }
            if (status.orderStatusId === OrderStatusId.ShippedToPartner) {
              const shipped = prettyDateAndTime(new Date(status.date));
              setEstimatedShipping(shipped);
              setEstimatedShippingMessage("Shipped on");
              setDeliveryShippingMessage("TRACKING");
              setEstimatedDelivery(restoConfig?.trackingId || "N/A");
            }
          });
        }
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    // Total retail
    const totalRetail = Number(retailProductPrice) + taxes + minOrderFeePrice + retailShippingPrice + oversizePrice;
    setTotalRetailPrice(totalRetail.toFixed(2));
  }, [retailProductPrice, retailShippingPrice, oversizePrice, minOrderFeePrice]);

  useEffect(() => {
    // Total cost
    const totalPartner = Number(partnerProductCost) + minOrderFeePriceAtPayment + oversizePriceAtPayment + partnerShippingCost;

    if (!isNaN(totalPartner) && !updatingValues)
      setTotalPartnerCost(parseFloat(totalPartner).toFixed(2));

  }, [partnerProductCost, partnerShippingCost, minOrderFeePriceAtPayment, oversizePriceAtPayment, shipperRates]);

  useEffect(() => {
    if (orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner) {
      setUnavailableAddressMsg("The address is no longer available");
    } else {
      setUnavailableAddressMsg("This address is no longer available. Please update the shipping address.");
    }
  }, [orderStatusId]);

  useEffect(() => {
    if (taxElm.current?.clientHeight && taxes) {
      setTaxHeight(taxElm.current?.clientHeight);
    }
  }, [taxes, taxElm.current?.clientHeight]);

  useEffect(() => {
    if (setSelectedStoreSuccess || setNewAddressOptionSuccess) {
      setUpdateOrderDetails(true);
    } else {
      setUpdateOrderDetails(false);
    }
  }, [setSelectedStoreSuccess, setNewAddressOptionSuccess]);

  useEffect(() => {
    if (Object.entries(orderStore).length > 0) {
      let order: any = localStorage.getItem(getOrdersStorageKey(orderKey));
      const orderObj = order ? JSON.parse(order) : null;

      if (orderObj && Object.entries(orderObj).length > 0) {
        if ((orderObj.ordersKey === orderKey)
          && (orderObj.storeName ? (selectedStore.name === orderObj.storeName) : true)) {
          setShowExclamationIcon(false);
        } else {
          setShowExclamationIcon(true);
        }
      } else {
        localStorage.setItem(getOrdersStorageKey(orderStore.ordersKey), JSON.stringify(orderStore));
      }
    }
  }, [orderStore, orderKey]);

  const downloadFilePopover = (name: string) => (
    <Popover id="download-popover">
      <Popover.Body>
        Download {name}
      </Popover.Body>
    </Popover>
  );

  const popover = (
    <Popover id="info-popover">
      <Popover.Body>
        You have selected a shipping address that differs from the pickup location chosen by the customer
      </Popover.Body>
    </Popover>
  );

  const InfoPopup = () => (
    <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement={isMobile() ? "bottom" : "top"} overlay={popover}>
      <span className={styles.exclamationIcon}><i className="icon fas fa-light fa-exclamation"></i></span>
    </OverlayTrigger>
  );

  const disableStoreMsg = (
    <Popover id="disabled-store-popover">
      <Popover.Body>
        {unavailableAddressMsg}
      </Popover.Body>
    </Popover>
  );

  const liveCheckout = useSelector((state: any) => state.App.liveCheckout);
  const liveRates = useSelector((state: any) => state.App.liveRates);
  const customerKey = useSelector((state: any) => state.App.customerKey);

  const checkCondition = (orderKey, orderStatusId, partnersKey) => {
    // Validate if Tracking Number notification should be sent
    if (showShippingAddress) {
      renderInModal(
        <TrackingNumber
          orderKey={orderKey}
          orderId={orderId}
          partnersKey={partnersKey}
          currentOrderStatusId={orderStatusId} />, "md"
      )
    } else {
      updateButtonState(orderKey, true, false);

      // Send READY notification
      onReadyClick(orderKey, orderStatusId, partnersKey, false)
        .then((res) => {
          updateButtonState(orderKey, false, true);
          setTimeout(() => {
            setUpdateOrderDetails(true);
            updateButtonState(orderKey, false, false);
          }, 2000);
        })
        .catch((error: any) => {
          updateButtonState(orderKey, false, false);
          setUpdateOrderDetails(false);
          console.log(error);
        });
    }
  }

  const orderActionClicked = (orderKey, orderStatusId, partnersKey, customerKey, serviceCode, liveCheckout, liveRates, fn) => {
    setChangingOrderStatus(true);

    fn(orderKey, orderStatusId, partnersKey, customerKey, serviceCode, liveCheckout, liveRates)
      .then((res) => {
        setUpdateOrderDetails(true);
        setChangingOrderStatus(false);
      })
      .catch((error: any) => {
        setUpdateOrderDetails(false);
        setChangingOrderStatus(false);
        console.log(error);
      });
  }

  const openShipToModal = () => {
    renderInModal(
      <ShipToModal
        defaultAddress={defaultAddress}
        selectedStore={selectedStore}
        partnersKey={partnersKey}
        orderId={orderId}
        orderKey={orderKey} />, "lg", "Ship To:"
    );
  }

  const AddressFormat = () => {
    const address: string = selectedStore?.address1?.trim() +
      "" + (selectedStore.address2?.trim() ? " " + selectedStore.address2?.trim() + ", " : ", ") +
      " " + selectedStore?.state + ", " + selectedStore.city?.trim() + ", " + selectedStore.postalCode?.trim();
    return <>
      {!isStoreAvailable
        ?
        <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement="top" overlay={disableStoreMsg}>
          <span className={[styles.shiptoAddress, (!isStoreAvailable && styles.disabledStore)].join(' ')}>{address}</span>
        </OverlayTrigger>
        :
        <>
          <span className={styles.shiptoAddress}>{address}
          </span>
        </>
      }
    </>;
  }

  const confirmCancelOrder = () => {
    cancelOrder({
      orderKey: orderKey,
      currentOrderStatusId: orderStatusId,
      partnersKey: partnersKey
    }).then((res) => {
      setUpdateOrderDetails(true);
      setChangingOrderStatus(false);
      renderInModal(
        <CustomMsgPopup
          title="The order submission was successfully removed!"
          showCancel={true}
          showConfirm={false}
          btnCancelTheme="blue"
          showCancelMsg="ACCEPT"
        />, "", "");
    }).catch((error: any) => {
      setUpdateOrderDetails(false);
      setChangingOrderStatus(false);
      console.log(error);
    });
  }

  const handleCancelClick = () => {
    renderInModal(
      <CustomMsgPopup
        title="Are you sure you want to remove this submission? This action cannot be undone"
        onClick={confirmCancelOrder}
        showCancel={true}
        showConfirm={true}
        showConfirmMsg="CONFIRM"
        showCancelMsg="CANCEL"
        storeCondition="cancelOrder"
      />, "", ""
    )
  }

  const storePopover = (
    <Popover id="info-popover">
      <Popover.Body>
        {selectedStore?.address1?.trim() +
          "" + (selectedStore.address2?.trim() ? " " + selectedStore.address2?.trim() + ", " : ", ") +
          " " + selectedStore?.state + ", " + selectedStore.city?.trim() + ", " + selectedStore.postalCode?.trim()}
      </Popover.Body>
    </Popover>
  );

  const StoreAddressPopover = () => (
    <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement={isMobile() ? "bottom" : "right"} overlay={storePopover}>
      <div className={styles.dot}><i className="icon fas fa-light fa-exclamation"></i></div>
    </OverlayTrigger>
  );

  const ShipDateInfo = () => {
    let estimatedDeliveryText: string;

    if (!trackingId)
      estimatedDeliveryText = estimatedDelivery?.split(" ")[0];
    else
      estimatedDeliveryText = estimatedDelivery;

    return <>
      {(orderStatusId !== OrderStatusId.Cancelled) && <div className={styles.shipDateColumn}>
        <span className={styles.estimatedShipDate}>{estimatedShippingMessagePlacingOrder && "*"} {estimatedShippingMessage}: <span>{estimatedShipping?.split(" ")[0]}</span></span>
        {estimatedDeliveryMessage &&
          <span className={styles.estimatedShipDate}>{estimatedDeliveryMessage}: <span>{estimatedDeliveryText}</span></span>
        }
        <span className={styles.quote}>{estimatedShippingMessagePlacingOrder}</span>
      </div>}
    </>
  }

  const getFormattedPrice = () => {
    return isFreeShipping
      ? (isNaN(totalRetailPrice) ? 0 : (parseFloat(totalRetailPrice) - retailShippingPrice).toFixed(2))
      : (isNaN(totalRetailPrice) ? 0 : parseFloat(totalRetailPrice).toFixed(2));
  }

  function getDisplayName(status) {
    switch (status) {
      case ManufacturingStatus.InPrint:
        return "Printing";
      case ManufacturingStatus.InSew:
        return "Sewing";
      default:
        return status;
    }
  }

  const ShippingRatesOptions = ({ shipperRates, selectedShipperRateCode, setSelectedShipperRate, orderKey, orderId }) => {
    const hasMultipleRates = shipperRates?.rates.length > 1;
    const rateCode = !hasMultipleRates && shipperRates?.rates[0].code;

    if (rateCode)
      setSelectedShipperRate(orderKey, orderId, shipperRates, rateCode);

    return (
      <div className={styles.pricingColumn}>
        {shipperRates ? shipperRates.rates.map((rate, i) => (
          <div className={styles.item} key={i}>
            {hasMultipleRates ? (
              <><span>
                <Form.Check
                  type="radio"
                  value={rate.code}
                  label={rate.name}
                  className={styles.blue}
                  onChange={() => setSelectedShipperRate(orderKey, orderId, shipperRates, rate.code)}
                  checked={selectedShipperRateCode === rate.code}
                />
              </span>
                <span className={styles.lightText}>
                  ${rate.amount.amount}
                </span>
              </>
            ) : (
              <span className={styles.uniqueRate}>
                <label>{rate.name}</label>
                <span>-</span>
                <span className={styles.lightText}>
                  ${rate.amount.amount}
                </span>
              </span>
            )}
          </div>
        )) : (
          <span>Loading shipping rates...</span>
        )}
      </div>
    );
  };

  const updateButtonState = (orderKey, isLoading, notificationSent) => {
    setButtonStates(prev => ({
      ...prev,
      [orderKey]: { isLoading, notificationSent }
    }));
  };

  if (error) {
    return <div className={styles.loadingText}>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className={styles.loadingText}>Loading...</div>;
  } else if (orderData && orderData.orderState) {
    return (
      <>
        <div className={styles.header}>
          <Button
            className={styles.backButton}
            faIconCls="fa-caret-left"
            label="BACK"
            onClick={() => history.goBack()} />
          {matchMedia && <ShipDateInfo />}
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.leftColumn}>
            <div className={styles.orderInfoContainer}>
              <div className={styles.orderDataColumn}>
                <div className={styles.orderInfoText}>Order #:  </div>
                <span className={styles.orderNumber}><a href={openOrderUrl} target="_blank">{orderKey}</a></span>
                <span className={styles.orderStatus}
                  style={{ color: (orderStatusId === OrderStatusId.Cancelled || orderStatusId === OrderStatusId.PaymentFailed) ? "red" : "" }}>
                  {orderStatusDisplayName}
                </span>
                <span className={styles.mStatus}>
                  {`${manufacturingStatus ? ` / ${getDisplayName(manufacturingStatus)}` : ''}`}
                </span>
              </div>
              {
                (orderStatusId === OrderStatusId.SentForManufacturing
                  || orderStatusId === OrderStatusId.StandBy
                  || orderStatusId === OrderStatusId.ShippedToPartner
                  || orderStatusId === OrderStatusId.Complete) &&
                <div className={styles.linksWrapper}>
                  <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement="bottom" overlay={downloadFilePopover("Packing Slip Copy")}>
                    <div>
                      {/* @ts-ignore */}
                      <PackingSlip
                        orderData={orderData}
                        partnerData={partner}>
                      </PackingSlip>
                    </div>
                  </OverlayTrigger>
                  <div>|</div>
                  <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement="bottom" overlay={downloadFilePopover("Receipt")}>
                    <div>
                      {/* @ts-ignore */}
                      <Receipt
                        orderData={orderData}
                        partnerData={partner}
                        coachData={customerInfo}
                      >
                      </Receipt>
                    </div>
                  </OverlayTrigger>
                  <div>|</div>
                  <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement="bottom" overlay={downloadFilePopover("Invoice")}>
                    <div>
                      {/* @ts-ignore */}
                      <Receipt
                        orderData={orderData}
                        partnerData={partner}
                        coachData={customerInfo}
                        isInvoice={true}
                      >
                      </Receipt>
                    </div>
                  </OverlayTrigger>
                </div>
              }
            </div>

            <div className={styles.flexRow}>
              <div className={styles.contactTitle}>
                Contact
              </div>
              <div className={styles.contactTitle}>
                <div className={styles.btnContactWrapper}>
                  <Button
                    onClick={() => setOpenContactAddress(!openContactAddress)}
                    label="More Info"
                    faIconCls={!openContactAddress ? "fas fa-chevron-down" : "fas fa-chevron-up"}
                    className={styles.reverseRow}
                  />
                </div>
              </div>
            </div>
            <div className={styles.contactContainer}>
              <div className={styles.contactCard}>
                <div className={["row", styles.customer].join(' ')}>
                  <div className="col-md-3 col-sm-3 col-3">
                    <div className={styles.iconContainer}>
                      <i className="icon far fa-address-card"></i>
                    </div>
                  </div>
                  <div className="col-md-9 col-sm-9 col-9">
                    <div className={["card-body", styles.padding5].join(' ')}>
                      <h5 className="card-title">{customerInfo.name}</h5>
                      <div className={styles.contactCardInfo}>
                        <div className={styles.emailAndPhone}>
                          <span title={customerInfo.email}>{customerInfo.email}</span>
                          <span className={styles.p05}> | </span>
                          <span><i className="fas fa-phone" /> {customerInfo.phone}</span>
                        </div>
                        <span className="card-text">
                          {customerInfo.organizationName && "Organization: " + customerInfo.organizationName.toUpperCase()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {openContactAddress &&
                <div className={styles.contactAddressCard}>
                  <div className={["row", styles.customer].join(' ')}>
                    <div className="col-md-3 col-sm-3 col-3">
                      <div className={styles.iconContainer}>
                        <i className="fas fa-map-marker"></i>
                      </div>
                    </div>
                    <div className="col-md-9 col-sm-9 col-9">
                      <div className={["card-body", styles.padding5].join(' ')}>
                        {((orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner) && showShippingAddress)
                          ?
                          <>
                            <h5 className="card-title">{shippingAddress.address1} {shippingAddress.address2}</h5>
                            <span>
                              {shippingAddress.city + ', ' + shippingAddress.state}
                            </span>
                            <p className={["card-text", styles.pt025].join(' ')}>
                              {shippingAddress.postalCode}
                            </p>
                          </>
                          :
                          <><h5 className="card-title">{customerInfo.streetAddress || "--"}</h5>
                            <span>
                              {(customerInfo.city || "--") + ', ' + (customerInfo.state || "--")}
                            </span>
                            <p className={["card-text", styles.pt025].join(' ')}>
                              {customerInfo.zip}
                            </p>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
              {!openContactAddress && <div className={styles.lineDivider}></div>}
            </div>

            <div className={styles.productsContainer}>
              {lowDpiLogos?.length > 0 &&
                <div className={styles.lowDpiIndicator}>
                  <div className={styles.iconAlert}>!</div>
                </div>
              }

              {orderData.orderState.map((productData: any) => {
                const lowLogoInfo = [];
                lowDpiLogos?.length > 0 && lowDpiLogos.find((logo) => {
                  if (logo[productData.ItemNumber])
                    lowLogoInfo.push(logo[productData.ItemNumber]);
                })

                return <ReviewItem
                  key={productData.Id}
                  product={productData}
                  partner={partner?.Partner}
                  lowQualityLogos={lowLogoInfo}
                />
              })}
            </div>
          </div>

          <div className={styles.rightColumn}>

            {!matchMedia && <ShipDateInfo />}

            <div className={styles.rushWrapper}>
            </div>
            <div className={styles.shippingWrapper}>
              <div className={styles.row}>
                <div className={styles.shipTo}>
                  <span>{orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner ? "Shipped to:" : "Ship to:"}</span>
                  {
                    Object.entries(selectedStore).length > 0 &&
                    <>
                      {!isStoreAvailable && <span className={styles.exclamationIcon}>
                        <i className="icon fas fa-light fa-exclamation"></i>
                      </span>}
                      {(showShippingAddress && (orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner))
                        ? (selectedStore ? <div className={styles.dotWrapper}><StoreAddressPopover /><span>{selectedStore.name}</span></div> : "--")
                        : <AddressFormat />
                      }
                    </>
                  }
                </div>
                {(!showShippingAddress && validStatuses.includes(orderStatusId as OrderStatusId))
                  &&
                  <Button
                    className={styles.editShipTo}
                    faIconCls="fa-light fa-pen"
                    label="EDIT"
                    onClick={openShipToModal} />
                }
              </div>
              <div className={styles.row}>
                <div className={styles.pickupLocation}>
                  <span>Pickup Location:</span>
                  {(isStoreAvailable && showExclamationIcon) && <InfoPopup />}
                  {(showShippingAddress && (orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner))
                    ? <span className={styles.store}>{shippingAddress ? "SHIP TO ADDRESS" : "--"}</span>
                    : <span className={[styles.store, (!isStoreAvailable && styles.disabledStore)].join(' ')}>{selectedStore ? selectedStore.name : "--"}</span>
                  }
                </div>
              </div>

              {((orderStatusId === OrderStatusId.Complete || orderStatusId === OrderStatusId.ShippedToPartner) && showShippingAddress) && (
                <div className={styles.shipToAddressContainer}>
                  <div className={styles.shipToRow}>
                    <div className={styles.shipTo}>
                      <span>Ship to:</span>
                    </div>
                    <span className={styles.shipToAddress}>
                      {[
                        shippingAddress?.address1?.trim(),
                        shippingAddress?.address2?.trim(),
                        shippingAddress?.city?.trim(),
                        shippingAddress?.state,
                        shippingAddress?.postalCode?.trim()
                      ].filter(Boolean).join(", ")}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {/* Show Shipping rates just for NEW, NEEDS REVIEW and PAYMENT FAILED statuses */}
            {(orderStatusId === OrderStatusId.PendingReview || orderStatusId === OrderStatusId.PaymentFailed) &&
              <div className={styles.priceContainter}>
                <div className={styles.row}>
                  <div className={styles.prices}>SHIPPING RATES<i className="icon fas fa-light fa-truck"></i></div>
                </div>
                <div className={styles.priceDetails}>
                  <div className={styles.row}>
                    <div className={styles.pricingColumn}>
                      <ShippingRatesOptions
                        selectedShipperRateCode={selectedShipperRateCode}
                        setSelectedShipperRate={setSelectedShipperRate}
                        shipperRates={shipperRates}
                        orderId={orderId}
                        orderKey={orderKey}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className={[styles.priceContainter,
            (orderStatusId === OrderStatusId.PendingReview || orderStatusId === OrderStatusId.PaymentFailed) ? styles.mt0 : ""].join(' ')}>
              <div className={styles.row}>
                <div className={styles.prices}>PRICES <i className="icon fas fa-light fa-tag"></i></div>
              </div>
              <div className={styles.priceDetails}>
                <div className={styles.row}>
                  <div className={styles.pricingColumn}>
                    <div className={styles.item} ref={taxElm}>
                      <span className={styles.text}>
                        Retail Product Price:
                      </span>
                      <span className={styles.lightText}>
                        ${totalProductPriceMinusShippingCost}
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.text}>
                        Retail Tax Price:
                      </span>
                      <span className={styles.lightText}>
                        ${taxes}
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.text}>
                        Retail Shipping Price:
                      </span>
                      <div className={[styles.lightText, isFreeShipping ? styles.displayRow : ''].join(' ')}>
                        <span className={isFreeShipping ? styles.lineThrough : ''}>${retailShippingPrice}</span> {isFreeShipping && "|"}
                        <span>{isFreeShipping && "FREE Shipping"}</span>
                      </div>
                    </div>
                    {minOrderFeePrice > 0 && <div className={styles.item}>
                      <span className={styles.text}>
                        Min Order Extra Charge:
                      </span>
                      <span className={styles.lightText}>
                        ${minOrderFeePrice}
                      </span>
                    </div>}
                    {oversizePrice > 0 &&
                      <div className={styles.item}>
                        <span className={styles.text}>
                          Oversized Jersey Fee:
                        </span>
                        <span className={styles.lightText}>
                          ${oversizePrice}
                        </span>
                      </div>}
                    <div className={styles.item}>
                      <span className={styles.text}>
                        Total Retail Price:
                      </span>
                      <span className={styles.lightText}>
                        ${getFormattedPrice()}
                      </span>
                    </div>
                  </div>
                  <div className={styles.pricingColumn}>
                    <div className={styles.item} style={{ height: taxHeight }}>
                      <span className={styles.partnerCost}>
                        Partner Product Cost:
                      </span>
                      <span className={styles.lightText}>
                        {updatingValues ? '--' : `$${totalProductCostMinusShippingCost?.toFixed(2)}`}
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.partnerCost}>
                        Cost Tax Price:
                      </span>
                      <span className={styles.lightText}>
                        {updatingValues ? '--' : `${costTaxDescription}`}
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.partnerCost}>
                        Partner Shipping Cost:
                      </span>
                      <span className={styles.lightText}>
                        {!partnerShippingCost ? '--' : `$${partnerShippingCost?.toFixed(2)}`}
                      </span>
                    </div>
                    {minOrderFeePriceAtPayment > 0 &&
                      <div className={styles.item}>
                        <span className={styles.partnerCost}>
                          Min Order Extra Charge:
                        </span>
                        <span className={styles.lightText}>
                          ${minOrderFeePriceAtPayment}
                        </span>
                      </div>
                    }
                    {oversizePriceAtPayment > 0 &&
                      <div className={styles.item}>
                        <span className={styles.partnerCost}>
                          Oversized Jersey Fee:
                        </span>
                        <span className={styles.lightText}>
                          ${oversizePriceAtPayment}
                        </span>
                      </div>}
                    <div className={styles.item}>
                      <span className={styles.partnerCost}>
                        Subtotal Partner Cost:
                      </span>
                      <span className={styles.totalPartnerCost}>
                        {updatingValues ? '--' : `$${totalPartnerCost}`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={(orderStatusId === OrderStatusId.PaymentFailed || orderStatusId === OrderStatusId.PendingReview
                || orderStatusId === OrderStatusId.ShippedToPartner) ? styles.priceButtonWrapper : styles.priceButton}>
                <div className={styles.column}>
                  {(
                    orderStatusId === OrderStatusId.PaymentFailed)
                    &&
                    <Button
                      disabled={
                        changingOrderStatus
                        || !selectedShipperRateCode
                        || !checkedRate
                        || updatingValues
                      }
                      theme="green"
                      className={styles.btn}
                      label={`${!changingOrderStatus ? "Pay" : ""}`}
                      faIconCls={`${!changingOrderStatus ? "fa-credit-card" : "fas fa-circle-notch fa-spin"}`}
                      handler={() => orderActionClicked(orderKey, orderStatusId, partnersKey, customerKey, selectedShipperRateCode, liveCheckout, liveRates, onPayClick)}
                    />
                  }
                  {(
                    orderStatusId === OrderStatusId.PendingReview) &&
                    <div className={styles.buttonsContainer}>
                      <Button
                        disabled={changingOrderStatus}
                        theme="red"
                        className={styles.btn}
                        label="Remove Submission"
                        handler={handleCancelClick}
                      />
                      <Button
                        disabled={
                          changingOrderStatus
                          || !selectedShipperRateCode
                          || !checkedRate
                          || updatingValues
                        }
                        theme="green"
                        className={styles.btn}
                        label={`${!changingOrderStatus ? "Approve and Pay" : ""}`}
                        faIconCls={`${!changingOrderStatus ? "" : "fas fa-circle-notch fa-spin"}`}
                        handler={() => orderActionClicked(orderKey, orderStatusId, partnersKey, customerKey, selectedShipperRateCode, liveCheckout, liveRates, onApproveAndPayClick)}
                      />
                    </div>
                  }
                  {(
                    orderStatusId === OrderStatusId.ShippedToPartner)
                    && <Button
                      disabled={buttonStates[orderKey]?.isLoading}
                      theme={buttonStates[orderKey]?.notificationSent ? "green" : "blue"}
                      label={(buttonStates[orderKey]?.notificationSent && !showShippingAddress) ? "NOTIFICATION SENT!" : (showShippingAddress ? "Mark as shipped" : "Mark as ready for pickup")}
                      className={styles.btn}
                      handler={() => !buttonStates[orderKey]?.isLoading && checkCondition(orderKey, orderStatusId, partnersKey)}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (noOrderFound) {
    return <div className={styles.loadingText}>No order found...</div>;
  } else {
    // do nothing
  }
}

export default withRouter(OrderDetails);
