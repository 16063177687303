import Button from '../Button';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { Address } from '../Orders/OrderDetails';
import { setSelectedStore } from '../../Application/UseCases/setSelectedStore';
import { setNewAddressOption } from '../../Application/UseCases/setNewAddressOption';
import { initPartnerData } from '../../Application/UseCases/Init/initPartnerData';
import { closeModal, renderInModal } from '../Modal';
import { isMobile } from '../../Utility/isMobile';
import CustomMsgPopup from '../CustomMsgPopup/CustomMsgPopup';
import { getOrdersStorageKey } from '../../Utility/Session';
import { STeams } from '../../ExternalAPI/STeams';

// Styles
import styles from './ShipToModal.module.scss';

interface Props {
  selectedStore?: Address;
  defaultAddress?: Address;
  partnersKey?: string;
  orderKey?: string;
  orderId?: string;
}

function ShipToModal(props: Props) {
  const [item, setItem] = useState({ addressRadio: "savedAddress" });
  const [selectedAddress, setSelectedAddress] = useState<Address>({ ...props.selectedStore } || {});
  const { addressRadio } = item;
  const initialAddressState: Address =
  {
    address1: "",
    address2: "",
    name: "",
    state: "",
    city: "",
    postalCode: "",
    salesTaxType: "origin",
    frontEndVisible: false
  };
  const [newAddress, setNewAddress] = useState<Address>(initialAddressState);
  const isValid = Boolean(newAddress.address1 && newAddress.name && newAddress.state && newAddress.city && newAddress.postalCode);
  const doneBtn: any = useRef('');
  const [updating, setUpdating] = useState<boolean>(false);
  const [storeNameErrorMsg, setStoreNameErrorMsg] = useState<string>("");
  let styleMsg: any = { display: "flex", justifyContent: "space-between" };
  const [addressOptions, setAddressOptions] = useState<Address[]>([]);
  const [stores, setStores] = useState<Address[]>([]);
  const triggerEvent: any = isMobile() ? "click" : "hover";

  useEffect(() => {
    const fetchData = async () => {
      const partner = await STeams.getPartnerByKey(props.partnersKey);
      const addressOptions = partner?.Partner[0].addressOptions;
      setAddressOptions(addressOptions);
      setStores(addressOptions.concat(props.defaultAddress));
    }

    fetchData()
      .catch(console.error);
  }, [])

  const onChangeState = e => setNewAddress(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

  const handleChange = e => {
    e.persist();
    setItem(prevState => ({
      ...prevState,
      addressRadio: e.target.value
    }));
  };

  function StateSelector() {
    return (
      <Form.Select
        name="state"
        value={newAddress.state || ''}
        aria-label="State"
        onChange={onChangeState}
        disabled={item?.addressRadio === "savedAddress"}
      >
        {statesArray.map((option: any, index: number) => {
          return <option key={index} value={option.value}>{option.label}</option>
        })}
      </Form.Select>
    );
  }

  function StoreSelector() {
    return (
      <Form.Select
        aria-label=""
        onChange={(event: any) => {
          const selected = event.target.value;

          const store: any = stores?.filter((item) => {
            return item.name === selected;
          })[0];
          setSelectedAddress(store);
        }}
        value={selectedAddress?.name}
        disabled={item?.addressRadio === "newAddress"}
      >
        {stores.map((option: any, index: number) => {
          return <option
            key={index}>
            {option.name}
          </option>
        })}
      </Form.Select>
    );
  }

  function validateAddressName() {
    const alreadyExist = stores?.filter((item) => {
      return item.name.toLowerCase().trim()
        === newAddress.name?.toLowerCase().trim();
    });

    return alreadyExist?.length;
  }

  function confirmSelectedStore(newAddress?: Address) {
    // set store location
    setSelectedStore({
      orderKey: props.orderKey,
      orderId: props.orderId,
      selectedStore: newAddress || selectedAddress
    }).then((res: any) => {
      setUpdating(false);
      initPartnerData({ partnersKey: props.partnersKey }).then((res: any) => {
        closeModal();
      });
    }, (error: any) => {
      setUpdating(false);
    });
  }

  function confirmAddress() {
    setStoreNameErrorMsg("");
    const newAddressOptions = [...addressOptions, newAddress];

    // add new address
    setNewAddressOption({
      partnersKey: props.partnersKey,
      addressOptions: newAddressOptions
    }).then((res: any) => {
      setUpdating(false);
      confirmSelectedStore(newAddress);
    }, (error: any) => {
      setUpdating(false);
    });
  }

  function openShipToModal() {
    renderInModal(
      <ShipToModal
        defaultAddress={props.defaultAddress}
        selectedStore={props.selectedStore}
        partnersKey={props.partnersKey}
        orderId={props.orderId}
        orderKey={props.orderKey} />, "lg", "Ship To:"
    );
  }

  function showWarningMsg(fn: Function) {
    renderInModal(
      <CustomMsgPopup
        title='You have selected a shipping address different than the pickup address chosen by the customer. Would you like to proceed?'
        onClick={fn}
        onClickCancel={openShipToModal}
        showCancel={true}
        showConfirm={true}
        showConfirmMsg="YES"
        showCancelMsg="NO"
      />, "", ""
    )
  }

  async function validateIfStoreExists() {
    const partner: any = await STeams.getPartnerByKey(props.partnersKey);

    if (partner?.Partner[0].defaultAddressName === selectedAddress.name) {
      return true;
    }

    const shippingOptions = partner?.Partner[0].addressOptions;
    const isAvailable = shippingOptions?.filter((store) => {
      return store.name === selectedAddress.name;
    });

    return isAvailable?.length > 0 ? true : false;
  }

  const changeStore = async () => {
    setUpdating(true);
    if (item.addressRadio === "savedAddress") {

      if (!await validateIfStoreExists()) {
        renderInModal(
          <CustomMsgPopup
            title='The selected store is no longer available. Please select another or create a new one'
            onClick={openShipToModal}
            showCancel={false}
            showConfirm={true}
            showConfirmMsg="CONFIRM"
          />, "", ""
        )
        return;
      }

      let order: any = localStorage.getItem(getOrdersStorageKey(props.orderKey));
      const orderObj = order ? JSON.parse(order) : null;

      if (orderObj && Object.entries(orderObj).length > 0) {
        if (orderObj.storeName !== selectedAddress.name) {
          showWarningMsg(confirmSelectedStore);
        } else {
          setSelectedStore({
            orderKey: props.orderKey,
            orderId: props.orderId,
            selectedStore: selectedAddress
          }).then((res: any) => {
            setUpdating(false);
            updatedBtn();
          }, (error: any) => {
            setUpdating(false);
            updatedBtnFailed();
          });
        }
      }
    } else {
      if (isValid) {
        if (validateAddressName()) {
          setStoreNameErrorMsg("location name already exists");
          setUpdating(false);
          return;
        } else {
          showWarningMsg(confirmAddress);
        }
      }
    }
  };

  function updatedBtn() {
    const el = doneBtn.current?.children[0];
    el.style.display = "flex";
    el.style.backgroundColor = "#198754";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-check');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "#1f76dc";
      el.children[0].classList.remove('fa', 'fa-check');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";
    }, 1000);
    return () => clearTimeout(timer);
  }

  function updatedBtnFailed() {
    const el = doneBtn.current?.children[0];
    el.style.display = "flex";
    el.style.backgroundColor = "#dc3545";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-times');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "#1f76dc";
      el.children[0].classList.remove('fa', 'fa-times');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";
    }, 1500);
    return () => clearTimeout(timer);
  }

  const popover = (
    <Popover id="info-popover">
      <Popover.Body>
        Creating a new address will automatically save it to your saved addresses.
      </Popover.Body>
    </Popover>
  );

  const InfoPopup = () => (
    <OverlayTrigger trigger={[triggerEvent, triggerEvent]} placement={isMobile() ? "bottom" : "right"} overlay={popover}>
      <div className={styles.dot}><i className="icon fas fa-light fa-exclamation"></i></div>
    </OverlayTrigger>
  );

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Form.Group className="mb-3 checkboxField" controlId="addressRadio">
            <Form.Label></Form.Label>
            <Form.Check
              type="radio"
              value="savedAddress"
              label="SAVED ADDRESS"
              className={styles.blue}
              onChange={handleChange}
              checked={addressRadio === "savedAddress"} />
          </Form.Group>
          <div className={styles.type}>
            <Form.Group className="mb-3" controlId="stores">
              <StoreSelector />
            </Form.Group>
            <div className={styles.storeAddress}>
              {(selectedAddress && Object.entries(selectedAddress).length > 0) &&
                <><i className='icon fas fa-light fa-location-arrow'></i>
                  <span className={`${item?.addressRadio === "newAddress" && styles.disabled}`}>
                    {selectedAddress?.address1}{selectedAddress.address2 ? " " + selectedAddress.address2 + ", " : ", "} {selectedAddress.state}, {selectedAddress.city}, {selectedAddress.postalCode}
                  </span></>}
            </div>
          </div>

          <div className={styles.newAddressRadio}>
            <Form.Group className="mb-3">
              <Form.Label></Form.Label>
              <Form.Check
                type="radio"
                value="newAddress"
                label="NEW ADDRESS"
                className={styles.blue}
                onChange={handleChange}
                checked={addressRadio === "newAddress"} />
            </Form.Group>
            <InfoPopup />
          </div>

          <div className={styles.type}>
            <Form>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label
                  className={item?.addressRadio === "savedAddress" && styles.disabled}
                  style={storeNameErrorMsg ? styleMsg : {}}>
                  Location Name {storeNameErrorMsg && <span className={styles.errorMsg}>{storeNameErrorMsg}</span>}
                </Form.Label>
                <Form.Control
                  onChange={onChangeState}
                  name="name"
                  value={newAddress.name || ''}
                  type="text"
                  placeholder=""
                  disabled={item?.addressRadio === "savedAddress"} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="address1">
                <Form.Label
                  className={item?.addressRadio === "savedAddress" && styles.disabled}>
                  Address Line 1
                </Form.Label>
                <Form.Control
                  name="address1"
                  value={newAddress.address1 || ''}
                  onChange={onChangeState}
                  type="text"
                  placeholder=""
                  disabled={item?.addressRadio === "savedAddress"} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="address2">
                <Form.Label className={item?.addressRadio === "savedAddress" && styles.disabled}>
                  Address Line 2
                </Form.Label>
                <Form.Control
                  name="address2"
                  value={newAddress.address2 || ''}
                  onChange={onChangeState}
                  type="text"
                  placeholder=""
                  disabled={item?.addressRadio === "savedAddress"} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="city">
                <Form.Label className={item?.addressRadio === "savedAddress" && styles.disabled}>
                  City
                </Form.Label>
                <Form.Control
                  name="city"
                  value={newAddress.city || ''}
                  onChange={onChangeState}
                  type="text"
                  placeholder=""
                  disabled={item?.addressRadio === "savedAddress"} />
              </Form.Group>

              <Form.Group className="mb-3" controlId="state">
                <Form.Label className={item?.addressRadio === "savedAddress" && styles.disabled}>
                  State
                </Form.Label>
                <StateSelector />
              </Form.Group>

              <Form.Group className="mb-3" controlId="postalCode">
                <Form.Label className={item?.addressRadio === "savedAddress" && styles.disabled}>
                  Postal Code
                </Form.Label>
                <Form.Control
                  name="postalCode"
                  value={newAddress.postalCode || ''}
                  onChange={onChangeState}
                  type="text"
                  placeholder=""
                  disabled={item?.addressRadio === "savedAddress"} />
              </Form.Group>
            </Form>
          </div>
          <div className={styles.button} ref={doneBtn}>
            <Button
              disabled={(item?.addressRadio === "newAddress" && !isValid)}
              faIconCls={updating ? 'fas fa-circle-notch fa-spin' : ""}
              className={styles.buttonConfirm}
              label={updating ? '' : "DONE"}
              theme="blue"
              onClick={changeStore}>
            </Button>
          </div>
        </div>
      </div >
    </>
  );
}

export default ShipToModal;

export const statesArray = [
  { value: '', label: '' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' }
];

// How to use it
// renderInModal(
//   <ShipToModal />, "lg", "Title here" 
// )
//
// "md" param is optional (set width to 500px)
//