import { RootState } from "../../../Application/Application";
import {app} from "../../../index";
import { UseCase } from '../../TypesAndInterfaces/UseCase';
import { STeams } from "../../../ExternalAPI/STeams";

export const signOutUseCase:UseCase = {
  type: 'SIGN_OUT',

  run: async () => {
    const store = app.getReduxStore();
    const state: RootState = store.getState();
    const sessionToken = state.Login.sessionToken;
    const logOutResult = await STeams.logOut(sessionToken);

    if (logOutResult)
    {
      localStorage.removeItem('steamspartner.sessionToken');
      localStorage.removeItem('steamspartner.apiToken');
    }
  }
};

export function signOut() {
  app.runUseCase(signOutUseCase);
}
