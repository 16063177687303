import { OrderStatusId } from "../Application/Domain/Order";
import { getStatusStorageKey } from "./Session";

// ordersKey are saved by status in storage --> SentForManufacturing: ["ordersKey", "ordersKey", "ordersKey"]
const addToUpdatedOrderStatuses = (storageKey: string, orderKey: string, orderStatusArray: string[], updatedOrderStatuses: { [key: string]: string[] }) => {
  orderStatusArray.push(orderKey);
  updatedOrderStatuses[storageKey] = orderStatusArray;
};

const removeFromUpdatedOrderStatuses = (storageKey: string, orderKey: string, orderStatusArray: string[], updatedOrderStatuses: { [key: string]: string[] }) => {
  const orderIndex = orderStatusArray.indexOf(orderKey);
  if (orderIndex !== -1) {
    orderStatusArray.splice(orderIndex, 1);
    updatedOrderStatuses[storageKey] = orderStatusArray;
  }
};

export const updateOrderStatuses = (orderKey: string, orderStatuses: { orderStatusId: string }[], currentOrderStatusId: string) => {
  // Validate visited orders for each status by ordersKey.
  const updatedOrderStatuses: { [key: string]: string[] } = {};

  for (const orderStatus of orderStatuses) {
    const { orderStatusId } = orderStatus;
    const storageKey = getStatusStorageKey(orderStatusId);
    const existingOrderStatus = localStorage.getItem(storageKey);

    if (existingOrderStatus) {
      let orderStatusArray: any = JSON.parse(existingOrderStatus);

      // Handle the old storage format if found
      if (!Array.isArray(orderStatusArray)) {
        orderStatusArray = [];
        localStorage.setItem(storageKey, JSON.stringify(orderStatusArray));
      }

      // Add it if it doesn't exist or remove it from the previous state storage key if the order changes state,
      // then add it in its new state
      if (orderStatusId === currentOrderStatusId) {
        // Remove orderKey from any existing status
        for (const [key, statusArray] of Object.entries(updatedOrderStatuses)) {
          if (Array.isArray(statusArray) && statusArray.includes(orderKey)) {
            removeFromUpdatedOrderStatuses(key, orderKey, statusArray, updatedOrderStatuses);
            break;
          }
        }

        // Add orderKey to the current status if it doesn't exist
        if (Array.isArray(orderStatusArray) && !orderStatusArray.includes(orderKey)) {
          addToUpdatedOrderStatuses(storageKey, orderKey, orderStatusArray, updatedOrderStatuses);
        }
      } else {
        // Remove orderKey from the current status if it exists
        if (Array.isArray(orderStatusArray)) {
          removeFromUpdatedOrderStatuses(storageKey, orderKey, orderStatusArray, updatedOrderStatuses);
        }
      }
    }
  }

  for (const [storageKey, orderStatusArray] of Object.entries(updatedOrderStatuses)) {
    localStorage.setItem(storageKey, JSON.stringify(orderStatusArray));
  }
};

export const showRedDot = (ordersKey, currentOrderStatusId) => {
  // Display red dot next to ordersKey if order wasn't visited yet
  const storageKey = getStatusStorageKey(currentOrderStatusId);
  const existingOrderStatus = localStorage.getItem(storageKey);
  const orderStatusArray = existingOrderStatus ? JSON.parse(existingOrderStatus) : [];

  return !orderStatusArray.includes(ordersKey);
};

// Check for duplicated keys on statuses that can be changed from backend
export const checkForDuplicates = () => {
  const pendingReviewKey = getStatusStorageKey(OrderStatusId.PendingReview);
  const sentForManufacturingKey = getStatusStorageKey(OrderStatusId.SentForManufacturing);
  const shippedToPartnerKey = getStatusStorageKey(OrderStatusId.ShippedToPartner);
  const needsAttentionKey = getStatusStorageKey(OrderStatusId.NeedsAttention);
  const completeKey = getStatusStorageKey(OrderStatusId.Complete);
  
  const pendingReviewOrders = getOrdersFromLocalStorage(pendingReviewKey);
  const sentForManufacturingOrders = getOrdersFromLocalStorage(sentForManufacturingKey);
  const shippedToPartnerOrders = getOrdersFromLocalStorage(shippedToPartnerKey);
  const needsAttentionOrders = getOrdersFromLocalStorage(needsAttentionKey);
  const completeOrders = getOrdersFromLocalStorage(completeKey);

  removeDuplicatesFromPendingReview(pendingReviewOrders, sentForManufacturingOrders, needsAttentionOrders);
  removeDuplicatesFromSentForManufacturing(sentForManufacturingOrders, needsAttentionOrders, shippedToPartnerOrders, completeOrders);
};

const getOrdersFromLocalStorage = (storageKey: string): string[] => {
  const ordersString = localStorage.getItem(storageKey) || '[]';
  let ordersArray = [];

  try {
    ordersArray = JSON.parse(ordersString);
  } catch (error) {
    console.error(`Error parsing JSON for storage key ${storageKey}:`, error);
  }

  if (!Array.isArray(ordersArray) || !ordersArray.every((order) => typeof order === 'string')) {
    ordersArray = [];
  }

  return ordersArray;
};

const removeDuplicatesFromPendingReview = (pendingReviewOrders, sentForManufacturingOrders, needsAttentionOrders) => {
  const duplicates = new Set([
    ...sentForManufacturingOrders,
    ...needsAttentionOrders
  ]);

  const updatedPendingReviewOrders = pendingReviewOrders.filter(orderKey => !duplicates.has(orderKey));
  localStorage.setItem(getStatusStorageKey(OrderStatusId.PendingReview), JSON.stringify(updatedPendingReviewOrders));
};

const removeDuplicatesFromSentForManufacturing = (sentForManufacturingOrders, needsAttentionOrders, shippedToPartnerOrders, completeOrders) => {
  const duplicates = new Set([
    ...needsAttentionOrders,
    ...shippedToPartnerOrders,
    ...completeOrders
  ]);

  const updatedSentForManufacturingOrders = sentForManufacturingOrders.filter(orderKey => !duplicates.has(orderKey));
  localStorage.setItem(getStatusStorageKey(OrderStatusId.SentForManufacturing), JSON.stringify(updatedSentForManufacturingOrders));
};


