import { STeams } from "../../../ExternalAPI/STeams";
import { UseCase } from '../../TypesAndInterfaces/UseCase';
import {app} from "../../../index";

declare const Cx ;

export function stringStartsWith (str: string, search: string): boolean {
  str = str.toLowerCase();
  search = search.toLowerCase();

  return str.substring(0, search.length) === search;
}

function isJSON(str) {
  try {
      return (JSON.parse(str) && !!str);
  } catch (e) {
      return false;
  }
}

export function cleanPhoneNumber (phone: string): string {
  const countryCode = '+1';

  if (stringStartsWith(phone, countryCode)) {
    // Remove country code
    phone = phone.substring(countryCode.length, phone.length);
  }

  // Numbers only
  phone = phone.replace(/\D/g,'');

  return countryCode + phone;
}

export interface RequestPhoneCodeOptions {
  phone: string;
}

export const requestPhoneCodeUseCase: UseCase = {
  type: 'REQUEST_PHONE_CODE',

  run: async ( options: RequestPhoneCodeOptions ) => {
    try{
      const phone = cleanPhoneNumber(options.phone);
      const apiRequestResult = await STeams.partnerRequestAccessByPhone({phone});
      if (apiRequestResult.inSystem && !apiRequestResult.error)
      {
        const dataToDispatch = Object.assign({
          // sentOnStart: options.sentOnStart,
          phone,
          success: true
        }, apiRequestResult);

        return Promise.resolve(dataToDispatch);
      }
      else {
        return Promise.reject({
          success: false, 
          errorMessage: apiRequestResult.error || 'Error requesting phone access.'
        });
      }
    }
    catch(e){

        return Promise.reject({
          success: false, 
          errorMessage: 'Error while requesting phone code: ' + ( isJSON(e) ? JSON.parse(e).status : e )
        });
      }
    }
};

export function requestPhoneCode( options: RequestPhoneCodeOptions ) {
  app.runUseCase( requestPhoneCodeUseCase, options );
}
