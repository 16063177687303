import { STeams } from "../../../ExternalAPI/STeams";
import { UseCase } from '../../TypesAndInterfaces/UseCase';
import { getPartnerSessionData } from "./helper/getPartnerSessionData"
import {app} from "../../../index";

declare const Cx ;

export interface VerifyEmailCodeUseCaseOptions {
  code: string;
  email: string;
}

export const verifyEmailCodeUseCase: UseCase = {
  type: 'VERIFY_EMAIL_CODE',

  run: async ( options: VerifyEmailCodeUseCaseOptions ) => {
    // TODO: Enter wrong code to test 400 results
    const verifyEmailResult = await STeams.verifyEmailCode({ hash: options.code, email: options.email });

    if(verifyEmailResult.verified) {
      localStorage.setItem('steamspartner.sessionToken', verifyEmailResult.sessionToken);

      const coachSessionData = await getPartnerSessionData(verifyEmailResult.sessionToken);
      return coachSessionData;
    }
    else {
      return Promise.reject({
        errorMessage: verifyEmailResult.error ? verifyEmailResult.error.data.error : 'Invalid request'
      });
    }
  }
};

export function verifyEmailCode( options: VerifyEmailCodeUseCaseOptions ) {
  app.runUseCase( verifyEmailCodeUseCase, options );
}
