import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";

export type setEnvironmentUseCaseOptions = {
  isProductive: boolean;
}

async function action(options: setEnvironmentUseCaseOptions): Promise<boolean> {
  return options.isProductive;
}

export const setEnvironmentUseCase: UseCase = {
  type: 'SET_ENVIRONMENT',
  run: action
};

export async function setEnvironment(options: setEnvironmentUseCaseOptions) {
  await app.runUseCase(setEnvironmentUseCase, options);
}