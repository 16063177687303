import { Address } from "../Components/Orders/OrderDetails";
import { Network } from "../Utility/Network";
import { getBackendUrl } from "../Utility/backendUrl";

export namespace STeams {

  const API_URL = getBackendUrl();

  export const getOrders = async () => {
    const result = await Network.get({
      endpoint: `${API_URL}/orders/readAll`
    });

    if (result.success)
      return result.data.Order;
  };

  interface ordersByPartnerOptions {
    partnersKey: string,
    page?: number,
    pageSize?: number,
    statusId?: string
  }

  interface accessByPhoneOptions {
    phone: string
  }

  interface accessByEmailOptions {
    email: string
  }

  interface VerifyPhoneCodePayload {
    phone: string,
    code: string
  }

  interface VerifyEmailCodePayload {
    email: string,
    hash: string
  }

  export interface AxiosError extends Error {
    response?: {
      data?: {
        error?: string;
      };
      status?: number;
    };
  }

  export type AppError = Error | AxiosError;

  const getErrorMessage = (error: AppError): string => {
    if ('response' in error && error.response?.data?.error) {
      return error.response.data.error;
    }
    return error.message;
  };

  export const partnerRequestAccessByPhone = async (payload: accessByPhoneOptions) => {
    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/phone/requestForAccess`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('partnerRequestAccessByPhone API result', result);
      return Promise.resolve(result.data);
    }
    else {
      const error = result?.error;
      const errorMessage = getErrorMessage(error);
      return Promise.reject(errorMessage);
    }
  };

  export const verifyPhoneCode = async (payload: VerifyPhoneCodePayload) => {

    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/phone/verifyCode`,
      payload: payload
    });

    if (result.success) {
      console.log('verifyPhoneCode API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  };

  export const partnerRequestAccessByEmail = async (payload: accessByEmailOptions) => {
    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/email/requestForAccess`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('partnerRequestAccessByPhone API result', result);
      return Promise.resolve(result.data);
    }
    else {
      const error = result?.error;
      const errorMessage = getErrorMessage(error);
      return Promise.reject(errorMessage);
    }
  };

  export const verifyEmailCode = async (payload: VerifyEmailCodePayload) => {

    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/email/verifyCode`,
      payload: payload
    });

    if (result.success) {
      console.log('verifyPhoneCode API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  };

  export const logOut = async (sessionToken: string) => {
    const payload = {
      sessionToken
    };

    // const logoutResult = await fetch(`${API_URL}/auth2/partner/logout`, {
    //   body: JSON.stringify(payload),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   method: 'POST'
    // });

    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/logout`,
      payload: JSON.stringify(payload),
    });

    if (result.success) {
      console.log('logOut API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export async function partnerHasSession(sessionToken?: string, partnersKey?: string, superSessionToken?: string) {
    const payload = {
      partnersKey,
      superSessionToken,
      sessionToken
    };

    const result = await Network.post({
      endpoint: `${API_URL}/auth2/partner/hasSession`,
      payload: payload,
    });

    if (result.success) {
      console.log('partnerHasSession API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getOrdersByPartner = async (payload: ordersByPartnerOptions) => {
    const result = await Network.get({
      endpoint: `${API_URL}/orders/readByPartner`,
      payload: payload
    });

    console.log('readByPartner API result', result);

    //TODO: Don't we want to know total on server?
    if (result.success) {
      const orderResult = typeof result?.data === 'string' ? JSON.parse(result?.data) : result?.data;
      console.log('getOrdersByPartner API result', orderResult);
      return Promise.resolve(orderResult);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  };

  export const ordersCountByPartner = async (partnersKey) => {
    const result = await Network.post({
      endpoint: `${API_URL}/partners/countOrdersByStatus`,
      payload: { partnersKey }
    });

    if (result.success) {
      console.log('ordersCountByPartner API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getAllOrderStatuses = async () => {
    const result = await Network.get({
      endpoint: `${API_URL}/orderStatuses/readAll`
    });

    if (result.success) {
      console.log('getAllOrderStatuses API result', result);
      return Promise.resolve(result.data.items);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const createPaymentIntent = async (orderKey: string) => {
    const result = await Network.post({
      endpoint: `${API_URL}/stripe/createPaymentIntent`,
      payload: {
        orderKey: orderKey
      }
    });

    if (result.success) {
      console.log('createPaymentIntent API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const createCheckoutSession = async (orderKey: string, partnersKey: string, customerKey: string, callBackURL: string, serviceCode: string, live: boolean, liveRates: boolean) => {
    const result = await Network.post({
      endpoint: `${API_URL}/stripe/createStripeSession`,
      payload: {
        orderKey,
        partnersKey,
        callBackURL,
        customerKey,
        serviceCode,
        live,
        liveRates
      }
    });

    if (result.success) {
      console.log('createCheckoutSession API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getConfigs = async () => {
    const result = await Network.get({
      endpoint: `${API_URL}/config/readAll`
    });

    if (result.success) {
      console.log('getConfigs API result', result);
      return Promise.resolve({ config: result?.data.items.Config });
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getOrdersReadByKeyUrl = async (orderKey: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/orders/readByKey`,
      payload: {
        key: orderKey
      }
    });

    if (result.success) {
      console.log('getOrdersReadByKeyUrl API result', result);
      return Promise.resolve({
        order: result?.data.items.Order
        , orderStatus: result?.data.items.OrderStatus
        , notes: result?.data.items.OrderNote
      });
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getPartnerByKey = async (partnerKey: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/partners/readByKey`,
      payload: {
        key: partnerKey
      }
    });

    if (result.success) {
      console.log('getPartnerByKey API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const setRushType = async (ordersKey: string, ordersId: string, rushType: string) => {
    const payload = {
      ordersKey,
      ordersId,
      rushType
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/setRushType`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      }
    });

    console.log('setRushType API result', result);
    if (result.success) {
      console.log('setRushType API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const setShippingRateCode = async (ordersKey: string, ordersId: string, shipRateCode: string, shipRatePrice: number) => {
    const payload = {
      ordersKey,
      ordersId,
      shipRateCode,
      shipRatePrice
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/setShipRateCode`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json'
        }
      }
    });

    console.log('setShipRateCode API result', result);
    if (result.success) {
      console.log('setShipRateCode API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const getCoachDataReadByKeyUrl = async (key: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/coaches/readByKey`,
      payload: {
        key: key
      }
    });

    if (result.success) {
      console.log('getCoachDataReadByKeyUrl API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const changeGrayGoodPrice = async (sportAssetsKey: string, partnersKey: string, id: string, price: number) => {
    const payload = {
      sportAssetsKey,
      partnersKey,
      id,
      price
    }

    const result = await Network.post({
      endpoint: `${API_URL}/sportAssets/changeGrayGoodPrice`,
      payload
    });

    if (result.success) {
      console.log('changeGrayGoodPrice API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const changeSelectedStore = async (ordersKey: string, ordersId: string, selectedStore: Address) => {
    const payload = {
      ordersKey,
      ordersId,
      selectedStore
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/changeSelectedStore`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('setSelectedStore API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const addNewAddressOption = async (partnersKey: string, addressOptions: Address[]) => {
    const payload = {
      partnersKey,
      addressOptions
    }

    const result = await Network.post({
      endpoint: `${API_URL}/partners/setAddressOption`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('addNewAddressOption API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const generateReceipt = async (orderData: any, partnerData: any, isInvoice: boolean) => {
    const payload = {
      orderData,
      partnerData,
      isInvoice
    }

    const result = await Network.post({
      endpoint: `${API_URL}/exportxls/generateReceipt`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('addNewAddressOption API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error);
    }
  }

  export const generatePackingSlipCopy = async (orderData: any, partnerData: any) => {
    const isCopy = true;
    const s = { order: orderData }
    const payload = {
      s,
      partnerData,
      isCopy
    }

    const result = await Network.post({
      endpoint: `${API_URL}/exportxls/generatePackingSlip`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('generatePackingSlip API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error);
    }
  }

  export const getShipperRates = async (order, live) => {
    const apiToken = localStorage.getItem('steamspartner.apiToken');
    const partnerInfo = order.orderSnippetData.partnerInfo;

    const payload = {
      ShipTo: {
        "CompanyName": partnerInfo.name,
        "AddressLine1": partnerInfo.address1,
        "City": partnerInfo.city,
        "StateCode": partnerInfo.state,
        "PostalCode": partnerInfo.postalCode,
        "CountryCode": "US"
      }
      , PartnersKey: partnerInfo.partnersKey
      , OrderKey: order.ordersKey
      , live
    }

    const result = await fetch(`${API_URL}/shipping/ups/rate`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${apiToken}`
      },
      credentials: 'include'
    })

    if (result.ok) {
      const jsonResp = await result.json();
      console.log('getShipperRates API result', jsonResp);
      return Promise.resolve(jsonResp);
    }
    else {
      return Promise.reject('Error fetching rates');
    }
  }

  export const getShipperShipping = async (orders) => {

    const partnerInfo = orders[0].orderSnippetData.partnerInfo;

    const total = orders.reduce((sum, o) => {
      return sum + o.orderState[0].TotalQuantity;
    }, 0);

    const payload = {
      ShipTo: {
        "CompanyName": partnerInfo.name,
        "AddressLine1": partnerInfo.address1,
        "City": partnerInfo.city,
        "StateCode": partnerInfo.state,
        "PostalCode": partnerInfo.postalCode,
        "CountryCode": "US"
      }
      , NumberOfJerseys: total
    }

    const result = await Network.post({
      endpoint: `${API_URL}/shipping/ups/shipping`,
      payload: payload,
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('getShipperRates API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error);
    }
  }

  export const setTrackingNumber = async (ordersKey: string, ordersId: string, trackingNumber: string) => {
    const payload = {
      ordersKey,
      ordersId,
      trackingNumber
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/setTrackingNumber`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('setTrackingNumber API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const setMinFeeValue = async (ordersKey: string, ordersId: string, minFeeConfigValue: number) => {
    const payload = {
      ordersKey,
      ordersId,
      minFeeConfigValue
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/setMinFeeValue`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('setMinFeeValue API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }

  export const updateOrder = async (ordersKey: string, ordersId: string, products: any, partnerKey) => {
    const payload = {
      ordersKey,
      ordersId,
      products,
      partnerKey
    }

    const result = await Network.post({
      endpoint: `${API_URL}/orders/updateOrder`,
      payload: JSON.stringify(payload),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    });

    if (result.success) {
      console.log('updateOrder API result', result);
      return Promise.resolve(result.data);
    }
    else {
      return Promise.reject(result.error.response.data.error);
    }
  }
}