import {STeams} from "../../ExternalAPI/STeams";
import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";
import { Address } from "../../Components/Orders/OrderDetails";

interface setSelectedStoreUseCaseOptions {
  orderKey: string,
  orderId: string,
  selectedStore: Address,
}

async function action(options: setSelectedStoreUseCaseOptions):Promise<setSelectedStoreUseCaseOptions> {

  await STeams.changeSelectedStore(options.orderKey, options.orderId, options.selectedStore);
  return options ;
}

export const setSelectedStoreUseCase:UseCase = {
  type: 'SET_SELECTED_STORE',
  run: action
};

export async function setSelectedStore(options: setSelectedStoreUseCaseOptions){
  await app.runUseCase(setSelectedStoreUseCase, options);
}