import { app } from "..";
import { RootState } from "../Application/Application";
import { loadStripe } from "@stripe/stripe-js";

function getPartnerKeySessionString(key:string )
{
  const store = app.getReduxStore();
  const state: RootState = store.getState();
  
  const partnersKey = state.Login.partnersKey;

  return `steamspartner.${partnersKey}.${key}`;
}

export function getVisitedOrdersStorageKey()
{
  return getPartnerKeySessionString('visitedOrders');
}

export function getOrdersStorageKey(orderId)
{
  const key = getPartnerKeySessionString('order');
  return `${key}.${orderId}`;
}

export function getStatusStorageKey(orderId)
{
  const store = app.getReduxStore();
  const state: RootState = store.getState();
  const isProductive = state.App.isProductive;
  const partnersKey = state.Login.partnersKey;

  const key = `steamspartner.${partnersKey}.${isProductive ? 'production' : 'edge'}`;
  return `${key}.${orderId}`;
}

export function createStripe(live)
{
  const apiKey = live ?
  'pk_live_51IQfoUGLfQqxxYUIIGeXka1GdyMtqVuSLmU95YtqgYoQi7t1WDLaLDEVEJ6H4GHAZ1Y8rSPITjIIR8JpCPYn1EXh00lrhJRApF' :
  'pk_test_51IQfoUGLfQqxxYUIokB9YgfG0e4US3OCfAgF8HlbgDZObSCy8BJMpyVoQogW3i1IGsagKwFow2mropdxlHv0bMPv00ftjPeZWc' ;
  return  loadStripe(apiKey);
}

export const queryString = (name:string, defaultValue?: any ) => {
  const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
  const val = urlParams.get(name)?.replace(' ', '+');
  if (val == undefined && defaultValue)
    return defaultValue ;
  return val ;
}

export const parseBooleanFromUrlParam = (paramNames, defaultValue = false) => {
  const searchParams = new URLSearchParams(decodeURIComponent(window.location.search));

  const liveCheckoutParam = searchParams.get(paramNames.liveCheckout);
  const liveRatesParam = searchParams.get(paramNames.liveRates);

  const liveCheckout = liveCheckoutParam !== null ? liveCheckoutParam.toLowerCase() === 'true' : defaultValue;
  const liveRates = liveRatesParam !== null ? liveRatesParam.toLowerCase() === 'true' : defaultValue;

  return { liveCheckout, liveRates };
};