import axios from "axios";
import { reportMessage } from "./rollbar"

function getToken(): string {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('apiToken') ?? localStorage.getItem('steamspartner.apiToken') ?? '';

  if (token) {
    localStorage.setItem('steamspartner.apiToken', token);
  }

  return token;
}

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      return Promise.reject(new Error('Authorization token is required.'));
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export namespace Network {
  interface requestParams {
    endpoint: string,
    payload?: any,
    config?: any
  }

  interface responseParams {
    success: boolean,
    data?: any,
    error?: any
  }

  export const get = async ({endpoint, payload = {}}: requestParams) : Promise<responseParams> => {
    try {
      const params = new URLSearchParams(payload).toString();
      let res = await axios.get(`${endpoint}?${params}`);
      let data = res.data;

      return Promise.resolve({ success: true, data });
    }
    catch(error)
    {
      reportMessage(endpoint, error);
      return Promise.reject({ success: false, error });
    }
  };

  export const post = async ({endpoint, payload = {}, config = {}}: requestParams): Promise<responseParams> => {
    try {
      let res = await axios.post(endpoint, payload, config);
      let data = res.data;

      return { success: true, data };
    }
    catch(error)
    {
      reportMessage(endpoint, error);
      return { success: false, error };
    }
  };

}
