import { OrderStatusId } from "../Domain/Order";
import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { coachContactInfo, getCustomerInfoReadByKeyUseCase } from "../UseCases/getCustomerInfoReadByKey";
import { ordersCountByPartnerResult, ordersCountByPartnersKeyUseCase } from "../UseCases/ordersCountByPartnersKey";
import { readOrdersByParamsUseCase } from "../UseCases/readOrdersByParams";
import { lastPageByStatusResult, setLastPageUseCase } from "../UseCases/setLastPage";

export interface OrderState {
  initComplete: boolean;
  orders: [];
  pageActive: number;
  pageSize: number;
  currentStatusId?: string;
  currentOrdersPage?: any;
  order: {};
  orderStatus: {};
  coachContactInfo: {};
  ordersCount: {};
  loading: boolean;
  lastPageByStatus: {};
}

const defaultOrderReducerData: OrderState = {
  initComplete: false,
  orders: [],
  pageActive: 1,
  pageSize: 10,
  currentStatusId: OrderStatusId.PendingReview,
  currentOrdersPage: undefined,
  order: {},
  orderStatus: {},
  coachContactInfo: {},
  ordersCount: undefined,
  loading: false,
  lastPageByStatus: {}
};

export const OrderReducer = function (state: OrderState = { ...defaultOrderReducerData }, useCaseResult: UseCaseResult) {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult: useCaseResult
  });

  switch (useCaseResult.type) {

    case readOrdersByParamsUseCase.type:
      {
        const result = useCaseResult.data;

        if (useCaseResult.start()) {
          return {
            ...state,
            loading: true
          };
        }

        if (useCaseResult.success()) {
          return {
            ...state,
            orders: typeof result.orders === 'string' ? JSON.parse(result.orders) : result.orders,
            currentStatusId: result.statusId,
            loading: false
          };
        }

        if (useCaseResult.failure()) {
          return {
            ...state,
            loading: false
          };
        }

        return state;
      }



    case getCustomerInfoReadByKeyUseCase.type:
      const customerInfo: coachContactInfo = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          coachContactInfo: customerInfo,
        });
      }
      break;

    case ordersCountByPartnersKeyUseCase.type:
      const result: ordersCountByPartnerResult = useCaseResult.data;
      // Temporaty condition for StandBy status
      const items = result && groupStatuses(result.ordersCountByPartner);

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          ordersCount: items
        });
      }
      break;

    case setLastPageUseCase.type:
      const data: lastPageByStatusResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          lastPageByStatus: data
        });
      }
      break;

    default:
      return state;
  }

  return state;
};

function groupStatuses(data: any) {
  let sentForManufacturingCount = 0;

  data?.forEach(item => {
    if (item.orderStatusId === OrderStatusId.SentForManufacturing || item.orderStatusId === OrderStatusId.StandBy) {
      sentForManufacturingCount += item.orders_count;
    }
  });

  data?.forEach(item => {
    if (item.orderStatusId === OrderStatusId.SentForManufacturing) {
      item.orders_count = sentForManufacturingCount;
    }
  });

  return data;
}

