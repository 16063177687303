import { Product } from '../Application/Domain/Product/Product';
import { useEffect, useRef, useState } from 'react';
import DisplayRosterGrid from './Roster/DisplayRosterGrid';
import { thumbnailUrlCxRenderUrl } from '../Utility/image';
import { isoStringToPrettyDateAndTime } from '../Utility/Date';

// Styles
import styles from './ReviewItem.module.scss';

interface Props {
  product: Product;
  partner: any;
  subTotal?: number;
  lowQualityLogos?: any[];
}

const ReviewItem = (props: Props) => {
  const product = props.product;
  const partner = props.partner;
  const designStyle = product.DesignStyle;
  const designStyleName = designStyle ? designStyle.Name : '';
  const grayGood = product.GrayGood;
  const grayGoodName = grayGood ? grayGood.Name : '';
  const cost = product.Cost;
  const rosterItems = product.RosterItems;
  const img: any = useRef([]);

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.parentElement.firstElementChild.style.display = 'none';
    imgElement.parentElement.style.display = "flex";
  }

  function onImgError(e, index) {
    const ele = img?.current[index];
    ele.style.margin = "3em";
    ele.style.width = "40px";
    ele.style.height = "40px";

    const imgElement = e.target;
    imgElement.onerror = "";
    imgElement.parentElement.firstElementChild.style.display = 'none';
    imgElement.parentElement.style.display = "flex";
    imgElement.src = './assets/images/image_logo.svg';
    return true;
  }

  const getTotal = (rosterItems: any) => {
    let total = 0;

    for (let i in rosterItems) {
      total += Number(rosterItems[i].Quantity);
    }

    return total;
  }

  const [rosterItemsCount, setRosterItemsCount] = useState<number>(getTotal(product.RosterItems));

  function getSubtotal() {
    const subtotal = (rosterItemsCount * cost);
    return subtotal?.toFixed(2);
  }

  return (
    <div className={styles.reviewItem}>
      <div className={styles.itemHeader}>
        <div className={styles.grayGood}>{grayGoodName}</div>
        <div className={styles.totalItems}>{rosterItemsCount} items</div>
        <div className={styles.price}>${cost} Each</div>
        <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>
          ${getSubtotal()}</span>
        </div>
      </div>
      <div className={styles.designStyle}>{designStyleName}</div>
      <div className={styles.itemNumber}>Item Number: {product.ItemNumber}</div>
      <div className={styles.body}>
        <div className={styles.bodyColumnOne}>
          <div className={styles.thumbsContainer}>
            <div className={styles.spinnerContainer}>
              <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
            </div>
            <img
              ref={el => img.current[0] = el}
              onLoad={handleOnLoad}
              onError={(e) => onImgError(e, 0)}
              src={thumbnailUrlCxRenderUrl({
                Size: 150,
                Camera: 'Front',
                DocId: product.SavedDocId,
                cadworxSessionId: partner.cadworxSessionId,
                type: "img"
              })} width={150} height={150} alt="front" />
            <img
              ref={el => img.current[1] = el}
              onLoad={handleOnLoad}
              onError={(e) => onImgError(e, 1)}
              src={thumbnailUrlCxRenderUrl({
                Size: 150,
                Camera: 'Back',
                DocId: product.SavedDocId,
                cadworxSessionId: partner.cadworxSessionId,
                type: "img"
              })} width={150} height={150} alt="back" />
          </div>

        </div>

        <div className={styles.rosterGridTable}>
          <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} />
        </div>
      </div>

      <div className={styles.rosterTotalContainer}>
        <div className={styles.columnFooter}>
          <div className={styles.wrapper}>
            {props.lowQualityLogos.length > 0 && <div className={styles.lowDpiContainer}>
              <span className={styles.title}>! LOW QUALITY LOGO</span>
              {props.lowQualityLogos?.map((logoData: any) => {
                return <span className={styles.label}>{logoData.name}: <span className={styles.red}>{Math.round(logoData.dpi)} DPI</span></span>
              })
              }
            </div>
            }
          </div>
        </div>
        <div className="roster-total">Roster Total: {rosterItemsCount}</div>
      </div>
    </div>
  );
};

export default ReviewItem;
