import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";
import { Address } from "../../Components/Orders/OrderDetails";

interface setNewAddressUseCaseOptions {
  partnersKey: string,
  addressOptions: Address[]
}

async function action(options: setNewAddressUseCaseOptions): Promise<setNewAddressUseCaseOptions> {

  await STeams.addNewAddressOption(options.partnersKey, options.addressOptions);
  return options;
}

export const setNewAddressOptionUseCase: UseCase = {
  type: 'SET_NEW_ADDRESS_OPTION',
  run: action
};

export async function setNewAddressOption(options: setNewAddressUseCaseOptions) {
  await app.runUseCase(setNewAddressOptionUseCase, options);
}