
import Button from '../Button';
import { Form } from 'react-bootstrap';
import { useRef, useState } from 'react';
import { STeams } from '../../ExternalAPI/STeams';
import { onReadyClick } from '../Orders/OrderActions';
import { closeModal } from '../Modal';

// Styles
import styles from './TrackingNumber.module.scss';

interface Props {
  orderKey: string;
  orderId: string;
  partnersKey: string;
  currentOrderStatusId: string;
}

export default function TrackingNumber(props: Props) {
  const confirmBtn: any = useRef('');
  const [trackingNumber, setTrackingNumber] = useState<string>(undefined);
  const [sending, setSending] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onChangeState = (e) => {
    e.preventDefault();
    setTrackingNumber(e.target.value);
  }

  const handleClose = () => {
    setSending(true);
    setError(false);

    // Save tracking number, then send notification to the coach
    STeams.setTrackingNumber(props.orderKey, props.orderId, trackingNumber)
      .then((r) => {
        onReadyClick(props.orderKey, props.currentOrderStatusId, props.partnersKey, true);
        setSending(false);
        setError(false);
        updatedBtn();

        // Close Tracking modal after 1 second
        setTimeout(() => {
          closeModal();
        }, 1000);
      })
      .catch((error) => {
        setSending(false);
        setError(true);
        updatedBtnFailed();
      });
  }

  function updatedBtn() {
    const el = confirmBtn.current?.children[0];
    el.style.display = "flex";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-check');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "#1f76dc";
      el.children[0].classList.remove('fa', 'fa-check');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";
    }, 1000);
    return () => clearTimeout(timer);
  }

  function updatedBtnFailed() {
    const el = confirmBtn.current?.children[0];
    el.style.display = "flex";
    el.style.backgroundColor = "#dc3545";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-times');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "green";
      el.children[0].classList.remove('fa', 'fa-times');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";
    }, 1500);
    return () => clearTimeout(timer);
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <Form>
            {error && <span className={styles.error}>There was an error trying to send your notification. Please try again</span>}
            <Form.Group className="mb-3" controlId="name">
              <Form.Control
                onChange={onChangeState}
                name="name"
                value={trackingNumber}
                type="text"
                placeholder="TRACKING NUMBER?"
              />
            </Form.Group>
          </Form>
          <div className={styles.button} ref={confirmBtn}>
            <Button
              className={styles.buttonConfirm}
              label={`${!sending ? "SEND NOTIFICATION" : ""}`}
              faIconCls={`${!sending ? "" : "fas fa-circle-notch fa-spin"}`}
              theme="green"
              disabled={sending || !trackingNumber}
              onClick={() => { handleClose() }}>
            </Button>
          </div>
        </div>
      </div >
    </>
  );
}

// How to use it
// renderInModal(
//   <TrackingNumber
//      orderKey={orderKey}
//      orderId={orderId}
//      partnersKey={partnersKey}
//      currentOrderStatusId={orderStatusId} />, "md"
// )
//
// "md" param is optional (set width to 500px)