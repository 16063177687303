import { useEffect, useRef, useState } from "react";
import { Form } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { RootState } from "../../Application/Application";
import { changeGrayGoodPrice } from "../../Application/UseCases/changeGrayGoodPrice";
import { getImageURL } from '../../Utility/image';
import { updatedBtn, updatedBtnFailed } from "../../Utility/updateAnimation";
import Button from "../Button";

// Styles
import styles from "./GrayGoods.module.scss";

interface Props { }

const GrayGoods = (props: Props) => {
  const partner: any = useSelector((state: RootState) => state.App.partnerData);
  const updatingPrice: boolean = useSelector((state: RootState) => state.App.updatingPrice);
  const updatingPriceFailed: boolean = useSelector((state: RootState) => state.App.updatingPriceFailed);
  const activeSportId: any = useSelector((state: RootState) => state.Gui.activeSportId);
  const sportName = partner.PartnerSport.find(g => g.sport.id === activeSportId)?.name;
  const activeGrayGoods = partner.PartnerGrayGood.filter(g => g.sport.id === activeSportId);
  const btn: any = useRef([]);
  const [currentElement, setCurrentElement] = useState(null);

  useEffect(() => {
    btn.current = btn.current.slice(0, activeGrayGoods.length);
  }, [activeGrayGoods]);

  useEffect(() => {
    if (updatingPrice && !updatingPriceFailed) {
      updatedBtn(currentElement);
    }
    if (updatingPriceFailed && !updatingPrice) {
      updatedBtnFailed(currentElement)
    }
  }, [updatingPrice, updatingPriceFailed]);

  const onHandleUpdate = (btn: any, grayGood: any, updatingPrice: boolean) => index => {
    changeGrayGoodPrice({
      partnersKey: grayGood.partnersKey
      , sportAssetsKey: grayGood.sport.sportAssetsKey
      , id: grayGood.grayGood.id
      , price: grayGood.price
    });

    setCurrentElement(btn?.current[index]);
  }

  return (<div className={styles.grayGoodsContainer}>
    <div className={styles.header}>
      <div className={styles.headerText}>
        Pricing
        <i className="fas fa-chevron-right"></i>
        <span className={styles.sport}>{sportName}</span>
      </div>
    </div>
    <div className={styles.list}>
      {
        activeGrayGoods.map((grayGood, i) => {
          const publishedThumbUrl = grayGood && grayGood.artRefs && grayGood.artRefs.publicDocId && getImageURL(grayGood.artRefs.publicDocId, 120, 120);
          return <div className={styles.item} key={grayGood.id}>
            <img className={styles.grayGoodImg} src={publishedThumbUrl} alt="GrayGood"></img>
            <span className={styles.name}>{grayGood.name}</span>
            <div className={styles.wrapInput}><span>$</span>
              <Form.Control
                required type="number"
                placeholder="Gray good Price"
                min="0.01"
                step="0.01"
                maxLength={6}
                onChange={(e) => {
                  grayGood.price = e.target.value;
                }}
                defaultValue={grayGood.price}
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.btnContainer} ref={el => btn.current[i] = el}>
              <Button
                faIconCls=""
                label="UPDATE PRICE"
                className={styles.updatePriceBtn}
                key={grayGood.id}
                active={true}
                theme="blue"
                onClick={() => {
                  onHandleUpdate(btn, grayGood, updatingPrice)(i);
                }}
              />
            </div>
          </div>
        })
      }
    </div>
  </div>)
}

export default GrayGoods;