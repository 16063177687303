import { MainContentAreas } from "../TypesAndInterfaces/MainContentAreas";
import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { initPartnerDataUseCase } from "../UseCases/Init/initPartnerData";
import { setLiveCheckoutUseCase } from "../UseCases/Init/setLiveCheckout";
import { setLiveRatesUseCase } from "../UseCases/Init/setLiveRates";
import { cancelOrderUseCase } from "../UseCases/cancelOrder";
import { changeGrayGoodPriceUseCase } from "../UseCases/changeGrayGoodPrice";
import { getAllOrderStatusesUseCase } from "../UseCases/getAllOrderStatuses";
import { setEnvironmentUseCase } from "../UseCases/setEnvironmet";
import { setLoadingOrdersUseCase } from "../UseCases/setLoadingOrders";
import { setNewAddressOptionUseCase } from "../UseCases/setNewAddressOption";
import { setSelectedStoreUseCase } from "../UseCases/setSelectedStore";

export interface AppState {
  initComplete: boolean;
  liveCheckout: boolean;
  liveRates: boolean;
  customerKey: string;
  allOrderStatuses: [];
  defaultOrderStatusId?: string;
  mainView: MainContentAreas,
  partnerData: {
    Partner: any[]
    , PartnerBranding: any[]
    , PartnerSport: any[]
    , PartnerGrayGood: any[]
    , PartnerDesignStyle: any[]
  },
  updatingPrice: boolean,
  updatingPriceFailed: boolean,
  loadingOrders: boolean,
  setSelectedStoreSuccess: boolean,
  setNewAddressOptionSuccess: boolean,
  cancelOrder: boolean,
  isProductive: boolean
}

const defaultAppReducerData: AppState = {
  initComplete: false,
  liveCheckout: false,
  liveRates: false,
  customerKey: undefined,
  allOrderStatuses: [],
  defaultOrderStatusId: undefined,
  mainView: MainContentAreas.Orders,
  partnerData: {
    Partner: []
    , PartnerBranding: []
    , PartnerSport: []
    , PartnerGrayGood: []
    , PartnerDesignStyle: []
  },
  updatingPrice: false,
  updatingPriceFailed: false,
  loadingOrders: false,
  setSelectedStoreSuccess: false,
  setNewAddressOptionSuccess: false,
  cancelOrder: false,
  isProductive: false
};

export const AppReducer = function (state: AppState = { ...defaultAppReducerData }, useCaseResult: UseCaseResult) {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult: useCaseResult
  });

  switch (useCaseResult.type) {

    case setLiveRatesUseCase.type:

      if (useCaseResult.success()) {
        if (useCaseResult.data.liveRates) {
          return Object.assign({}, state, {
            liveRates: useCaseResult.data.liveRates
          });
        }
      }
      return state;

    case setLiveCheckoutUseCase.type:

      if (useCaseResult.success()) {
        if (useCaseResult.data.liveCheckout) {
          return Object.assign({}, state, {
            liveCheckout: useCaseResult.data.liveCheckout,
            customerKey: useCaseResult.data.liveCheckout ? state.partnerData?.Partner[0]?.stripeCustomerKeyLive : state.partnerData?.Partner[0]?.stripeCustomerKey
          });
        }
      }
      return state;

    case initPartnerDataUseCase.type:
      const partnerData = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          partnerData: {
            Partner: partnerData.Partner[0]
            , PartnerBranding: partnerData.PartnerBranding[0]
            , PartnerSport: partnerData.PartnerSport
            , PartnerGrayGood: partnerData.PartnerGrayGood
            , PartnerDesignStyle: partnerData.PartnerDesignStyle
          },
          customerKey: state.liveCheckout ? partnerData.Partner[0].stripeCustomerKeyLive : partnerData.Partner[0].stripeCustomerKey
        });
      }
      break;

    case changeGrayGoodPriceUseCase.type:
      if (useCaseResult.failure()) {
        return Object.assign({}, state, {
          updatingPrice: false,
          updatingPriceFailed: true
        });
      }

      if (useCaseResult.start()) {
        return Object.assign({}, state, {
          updatingPrice: true,
          updatingPriceFailed: false
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          updatingPrice: false,
          updatingPriceFailed: false
        });
      }
      break;

    case setSelectedStoreUseCase.type:
      if (useCaseResult.start()) {
        return Object.assign({}, state, {
          setSelectedStoreSuccess: false,
          setNewAddressOptionSuccess: false
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          setSelectedStoreSuccess: true,
          setNewAddressOptionSuccess: false
        });
      }
      break;

    case setNewAddressOptionUseCase.type:
      if (useCaseResult.start()) {
        return Object.assign({}, state, {
          setSelectedStoreSuccess: false,
          setNewAddressOptionSuccess: false
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          setSelectedStoreSuccess: false,
          setNewAddressOptionSuccess: true
        });
      }
      break;

    case cancelOrderUseCase.type:
      if (useCaseResult.failure()) {
        return Object.assign({}, state, {
          cancelOrder: false
        });
      }

      if (useCaseResult.start()) {
        return Object.assign({}, state, {
          cancelOrder: true
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          cancelOrder: false
        });
      }
      break;

    case setLoadingOrdersUseCase.type:
      if (useCaseResult.success()) {
        const loadingOrdersByStatus = useCaseResult.data;
        return Object.assign({}, state, {
          loadingOrders: loadingOrdersByStatus
        });
      }
      break;

    case getAllOrderStatusesUseCase.type:
      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          allOrderStatuses: useCaseResult.data.allOrderStatuses
        });
      }
      break;

    case setEnvironmentUseCase.type:
      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          isProductive: useCaseResult.data
        });
      }
      break;

    default:
      return state;
  }

  return state;
};


