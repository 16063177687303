import { app } from "../..";
import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";

async function action() {
  const statuses = await STeams.getAllOrderStatuses();

  return { allOrderStatuses: statuses };
}

export const getAllOrderStatusesUseCase: UseCase = {
  type: 'GET_ALL_ORDER_STATUSES',
  run: action
};

export async function getAllOrderStatuses() {
  await app.runUseCase(getAllOrderStatusesUseCase);
}