import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";

export type setLoadingOrdersUseCaseOptions = {
  loadingOrdersByStatus: boolean;
}

export type setLoadingOrdersUseCaseResult = {
  loadingOrdersByStatus: boolean;
}

async function action(options: setLoadingOrdersUseCaseOptions):Promise<boolean> {

  return options.loadingOrdersByStatus;
}

export const setLoadingOrdersUseCase:UseCase = {
  type: 'loadingOrdersByStatus',
  run: action
};

export async function setLoadingOrders(options: setLoadingOrdersUseCaseOptions){
  await app.runUseCase(setLoadingOrdersUseCase, options);
}
