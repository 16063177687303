
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { readOrdersByParams } from "../../Application/UseCases/readOrdersByParams";

// Styles
import styles from "./Pagination.module.scss";

const Pagination = ({ range, setPage, page, slice, currentStatusId, partnersKey }) => {
  const [visibleRange, setVisibleRange] = useState<any>({ first: 1, last: 5 });

  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
    if (page >= 3 && (page <= range.slice(-1)[0] - 1)) {
      setVisibleRange({ first: (page - 2), last: (page + 2) });
    }

    if (page === range.slice(-1)[0]) {
      const lastPage = Math.max(range.slice(-1)[0], 1);
      const firstPage = Math.max(range.slice(-1)[0] - 4, 1);
      setVisibleRange({ first: firstPage, last: lastPage });
    }

  }, [slice, page, setPage]);

  function nextPage() {
    setPage(page + 1);
    handleClick(page + 1);
  }

  function previousPage() {
    setPage(page - 1);
    handleClick(page - 1);
  }

  function goToLastPage() {
    const lastPage = range.slice(-1)[0];

    setPage(lastPage);
    handleClick(lastPage);
  }

  function goToFistPage() {
    setPage(1);
    handleClick(1);
    setVisibleRange({ first: 1, last: 5 });
  }

  function handleClick(pageNumber) {
    readOrdersByParams({
      statusId: currentStatusId,
      page: pageNumber,
      pageSize: 10,
      partnersKey: partnersKey
    })
  }

  return (
    <div className={styles.pagination}>
      {range.length ? <button
        disabled={page === range[0]}
        className={`${styles.button} ${page === range[0] && styles.noClick}`}
        onClick={() => previousPage()}>
        <i className="fas fa-chevron-left"></i>
      </button> : null}
      {range.map((el, index) => {
        if (el < (visibleRange.first) && el === range[0]) {
          return <div key={uniqueId()} className={styles.dotsAndNumberWrapper}><button
            className={`${styles.button} ${page === 1 ? styles.activeButton : styles.inactiveButton
              }`}
            onClick={() => goToFistPage()}>
            1
          </button>
            <button
              className={styles.dotsButton}>
              {(page - 2 || page - 1) === range[0] ? null : "..."}
            </button>
          </div>
        }
        else if (el >= visibleRange.first && el <= visibleRange.last) {
          return <div key={uniqueId()} className={styles.dotsAndNumberWrapper}>
            <button
              className={`${styles.button} ${page === el ? styles.activeButton : styles.inactiveButton
                }`}
              onClick={() => {
                handleClick(el)
                setPage(el)
              }}>
              {el}
            </button></div>
        }
        else {
          if (el === (range.slice(-1)[0])) {
            return <div key={uniqueId()} className={styles.dotsAndNumberWrapper}><button
              className={styles.dotsButton}>
              {(page + 2 || page + 1) === range.slice(-1)[0] ? null : "..."}
            </button>
              <button
                className={`${styles.button} ${page === range.slice(-1)[0] ? styles.activeButton : styles.inactiveButton
                  }`}
                onClick={() => goToLastPage()}>
                {range.slice(-1)[0]}
              </button></div>
          }
        }
      })}
      {range.length ? <button
        disabled={page === range.slice(-1)[0]}
        className={`${styles.button} ${page === range.slice(-1)[0] && styles.noClick}`}
        onClick={() => nextPage()}>
        <i className="fas fa-chevron-right"></i>
      </button> : null}
    </div >
  );
};

export default Pagination;