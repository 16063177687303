import { useSelector } from 'react-redux';
import { RootState } from '../../Application/Application';
import Button from '../Button';
import { closeModal } from '../Modal';

// Styles
import styles from './CustomMsgPopup.module.scss';

interface Props {
  title?: React.ReactNode; // it allows JSX elements.
  url?: string;
  onClick?: Function;
  onClickCancel?: Function;
  showCancel?: boolean;
  showConfirm?: boolean;
  showCancelMsg?: string;
  showConfirmMsg?: string;
  btnConfirmTheme?: string;
  btnCancelTheme?: string;
  confirmIcon?: string;
  cancelIcon?: string;
  storeCondition?: string;
}

export default function CustomMsgPopup({
  title,
  url,
  onClick,
  onClickCancel,
  showCancel = true,
  showConfirm = true,
  showCancelMsg = 'CANCEL',
  showConfirmMsg = '',
  btnConfirmTheme = 'blue',
  btnCancelTheme = 'gray',
  confirmIcon = '',
  cancelIcon = '',
  storeCondition = '',
}: Props) {

  const validateCondition = useSelector(
    (state: RootState) => state.App[storeCondition],
  );

  const titleContent = url ? (
    <>
      {title}{' '}
      <a
        className={styles.urlLink}
        href={url}
        target="_self">
        {url}
      </a>
    </>
  ) : (
    title
  );

  const confirmButtonClassName = validateCondition || confirmIcon
    ? styles.confirmButtonWithIcon
    : styles.confirmButton;

  const cancelButton = showCancel && (
    <Button
      faIconCls={cancelIcon}
      className={styles.cancelButton}
      theme={btnCancelTheme}
      label={showCancelMsg}
      onClick={onClickCancel || closeModal}
    />
  );

  const confirmButton = showConfirm && (
    <Button
      faIconCls={
        validateCondition ? 'fas fa-circle-notch fa-spin' : confirmIcon
      }
      className={confirmButtonClassName}
      theme={btnConfirmTheme}
      label={validateCondition ? '' : showConfirmMsg}
      onClick={onClick}
    />
  );

  const buttons = [cancelButton, confirmButton].filter(Boolean);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.body}>{titleContent}</div>
        <div className={buttons.length === 1 ? styles.oneButton : styles.twoButtons}>
          {buttons}
        </div>
      </div>
    </>
  );
}

// How to use it -- (not all params are required)

//<CustomMsgPopup
//  title="" --> main content
//  url="" --> url option in title
//  showCancel={} --> boolean
//  showConfirm={} --> boolean
//  btnConfirmTheme="" --> set another color theme (default blue)
//  btnCancelTheme="" --> set another color theme (default gray)
//  showCancelMsg="" --> cancel btn text
//  showConfirmMsg="" --> confirm btn text
//  cancelIcon="" --> add icon to cancel btn
//  confirmIcon="" --> add icon to confirm btn
//  onClick={} --> on confirm btn click
//  onClickCancel={} --> on cancel btn click (close modal as defaul)
//  storeCondition="" --> use store param to add a loading spinner in confirm btn
// /> 
