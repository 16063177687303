import {UseCase} from "../../TypesAndInterfaces/UseCase";
import {app} from "../../../index";

export type setLiveRatesOptions = {
  liveRates: boolean;
}

export type setLiveRatesResult = {
  liveRates: boolean;
}

async function action(options: setLiveRatesOptions):Promise<setLiveRatesResult> {

  return { liveRates: options.liveRates };
}

export const setLiveRatesUseCase:UseCase = {
  type: 'setLiveRates',
  run: action
};

export async function setLiveRates(options: setLiveRatesOptions){
  await app.runUseCase(setLiveRatesUseCase, options);
}
