import { useState, useEffect } from 'react';
import { STeams } from '../ExternalAPI/STeams';
import { saveAs } from "file-saver";

// Styles
import styles from './Receipt.module.scss';
import { generateZipBlob } from '../Utility/zip';

interface PackingSlipProps {
  orderData: any;
  packingSlipBlob: any;
  partnerData: any;
}

function toArrayBuffer(buf) {
  const ab = new ArrayBuffer(buf.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buf.length; ++i) {
    view[i] = buf[i];
  }
  return ab;
}

export function PackingSlip(props: PackingSlipProps) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await STeams.generatePackingSlipCopy(props.orderData, props.partnerData?.Partner);
      const ret = await response;
      const blobNames = [];
      const blobs = [];
      let fileName = null;

      ret.forEach((xlsData, i) => {
        const buffer = toArrayBuffer(xlsData.data);
        const xlsBlob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        fileName = props.orderData.ordersKey + "-Packing-Slip";

        const productFileName = "(" + (i + 1) + ")_"
          + props.orderData.orderState[i].GrayGood.Name
          + "_" + props.orderData.orderState[i].DesignStyle.Name
          + "_" + props.orderData.ordersKey + ".xlsx";

        blobNames.push(productFileName);
        blobs.push(xlsBlob);
      });

      generateZipBlob(fileName, blobNames, blobs);
      setLoading(false);
    }

    if (loading) {
      // call the function
      fetchData()
        // make sure to catch any error
        .catch((error: any) => {
          setLoading(false);
          console.log(error);
        });
    }
  });

  return (
    <div className={styles.linksWrapper} style={{ pointerEvents: loading ? "none" : "auto", opacity: loading ? "0.7" : "1" }}>
      <div className={styles.link} onClick={() => { setLoading(true) }}>
        <i className={loading ? "icon fas fa-circle-notch fa-spin" : "icon fas fa-light fa-clipboard-list"}></i>
        <a>PACKING SLIP COPY</a>
      </div>
    </div>
  );
}