import { STeams } from "../../../ExternalAPI/STeams";
import { UseCase } from '../../TypesAndInterfaces/UseCase';
import {app} from "../../../index";

declare const Cx ;

export function stringStartsWith (str: string, search: string): boolean {
  str = str.toLowerCase();
  search = search.toLowerCase();

  return str.substring(0, search.length) === search;
}

export function cleanEmailNumber (email: string): string {
  const countryCode = '+1';

  if (stringStartsWith(email, countryCode)) {
    // Remove country code
    email = email.substring(countryCode.length, email.length);
  }

  // Numbers only
  email = email.replace(/\D/g,'');

  return countryCode + email;
}

export interface RequestEmailCodeOptions {
  email: string;
}

export const requestEmailCodeUseCase: UseCase = {
  type: 'REQUEST_EMAIL_CODE',

  run: async ( options: RequestEmailCodeOptions ) => {
    try{
      const apiRequestResult = await STeams.partnerRequestAccessByEmail({email: options.email});
      if (apiRequestResult.inSystem && !apiRequestResult.error)
      {
        const dataToDispatch = Object.assign({
          // sentOnStart: options.sentOnStart,
          email: options.email,
          success: true
        }, apiRequestResult);

        return Promise.resolve(dataToDispatch);
      }
      else {
        return Promise.reject({
          success: false, 
          errorMessage: apiRequestResult.error || 'Error requesting email access.'
        });
      }
    }
    catch(e){
        return Promise.reject({
          success: false, 
          errorMessage: e.response.data.error
        });
      }
    }
};

export function requestEmailCode( options: RequestEmailCodeOptions ) {
  app.runUseCase( requestEmailCodeUseCase, options );
}
