import { Elements } from "@stripe/react-stripe-js";
import { OrderStatusId } from "../../Application/Domain/Order";
import { STeams } from "../../ExternalAPI/STeams";
import { Network } from "../../Utility/Network";
import { createStripe } from "../../Utility/Session";
import { getBackendUrl } from "../../Utility/backendUrl";
import { updateOrderStatuses } from "../../Utility/visitedOrdersByStatus";
import { renderInModal } from "../Modal";
import CheckoutForm from "./CheckoutForm";
import { ordersCountByPartnersKey } from "../../Application/UseCases/ordersCountByPartnersKey";

// Load Stripe, get back promise
// This allows the child components to access the Stripe service via the Elements consumer.
const API_URL = getBackendUrl();

export const onApproveAndPayClick = async (orderKey: string, currentOrderStatusId: string, partnersKey: string, customerKey: string, serviceCode: string, liveCheckout = false, liveRates = false) => {
  const stripePromise = createStripe(liveCheckout)
  // Set status to "Approved, Pending Payment"
  const data = await Network.post({
    endpoint: `${API_URL}/orders/changeStatus`,
    payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.PaymentFailed }
  });

  if (data.success) {
    // Show checkout form for user to enter payment
    renderInModal(<Elements stripe={stripePromise} >
      <CheckoutForm
        orderKey={orderKey}
        partnersKey={partnersKey}
        customerKey={customerKey}
        live={liveCheckout}
        serviceCode={serviceCode}
        liveRates={liveRates} />
    </Elements>)

    // Refresh orders list
    await updateTotalOrdersByStatus(orderKey, currentOrderStatusId, partnersKey);
  }
};

export const onPayClick = async (orderKey: string, currentOrderStatusId: string, partnersKey: string, customerKey: string, serviceCode: string, liveCheckout = false, liveRates = false) => {
  const stripePromise = createStripe(liveCheckout)
  // User had previously "Approved", they just want to pay.
  // Show checkout form for user to enter payment
  renderInModal(<Elements stripe={stripePromise} >
    <CheckoutForm
      orderKey={orderKey}
      customerKey={customerKey}
      partnersKey={partnersKey}
      live={liveCheckout}
      serviceCode={serviceCode}
      liveRates={liveRates} />
  </Elements>)

  // Refresh orders list
  await updateTotalOrdersByStatus(orderKey, currentOrderStatusId, partnersKey);
};

export const onReadyClick = async (orderKey, currentOrderStatusId, partnersKey, sendTrackingNumber, updateStateFn?) => {
  if (updateStateFn) updateStateFn(orderKey, true, false);

  const data = await Network.post({
    endpoint: `${API_URL}/orders/changeStatus`,
    payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.Complete, sendTrackingNumber }
  });

  if (data.success) {
    if (updateStateFn) updateStateFn(orderKey, false, true);
    await updateTotalOrdersByStatus(orderKey, currentOrderStatusId, partnersKey);

    setTimeout(async () => {
      if (updateStateFn) updateStateFn(orderKey, false, false);
    }, 2500);
  } else {
    if (updateStateFn) updateStateFn(orderKey, false, false);
  }
};

export const onResetStatus = async (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
  // Set status to "Complete".  Partner communicating the order is done.
  const data = await Network.post({
    endpoint: `${API_URL}/orders/changeStatus`,
    payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.PendingReview }
  });

  if (data.success) {
    // Refresh orders list
    await updateTotalOrdersByStatus(orderKey, currentOrderStatusId, partnersKey);

    return data;
  }
};

export const onCancelClick = async (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
  // Set status to "Cancel".  Partner communicating the order is done.
  const data = await Network.post({
    endpoint: `${API_URL}/orders/changeStatus`,
    payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.Cancelled }
  });

  if (data.success) {
    // Refresh orders list
    await updateTotalOrdersByStatus(orderKey, currentOrderStatusId, partnersKey);

    return data;
  }
};

const updateTotalOrdersByStatus = async (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
  const statuses = await STeams.getAllOrderStatuses();

  updateOrderStatuses(orderKey, statuses, currentOrderStatusId);
  ordersCountByPartnersKey({ partnersKey: partnersKey })
}
